import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./Modal.module.sass";
import Icon from "../Icon";

const Modal = (props) => {
  const {
    outerClassName,
    visible,
    onClose,
    children,
    title,
    disableGoBackButton = false,
    modalId = "",
    disableOutsideClose = false,
  } = props;

  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal" + modalId ?? "");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  /**
   *
   */
  return createPortal(
    visible && (
      <div id={"modal" + modalId ?? ""} className={styles.modal}>
        <div className={cn(styles.outer, outerClassName)}>
          {disableOutsideClose ? (
            <ModalBody
              title={title}
              disableGoBackButton={disableGoBackButton}
              children={children}
              onClose={onClose}
              closeClass={props.closeClass}
            />
          ) : (
            <OutsideClickHandler onOutsideClick={onClose}>
              <ModalBody
                title={title}
                disableGoBackButton={disableGoBackButton}
                children={children}
                onClose={onClose}
              />
            </OutsideClickHandler>
          )}
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;

const ModalBody = ({
  title,
  disableGoBackButton,
  children,
  onClose,
  closeClass,
}) => {
  return (
    <>
      {title && (
        <h1 className="text-2xl p-5">
          {disableGoBackButton ? null : <Icon name="arrow-left" size="32" />}
          {title}
        </h1>
      )}
      <div className="mt-5">{children}</div>
      <button
        className="absolute top-5 right-5 text-2xl hover:text-amber-500"
        onClick={onClose}
      >
        <i className="mdi mdi-close"></i>
      </button>
    </>
  );
};
