const initialState = {
  tranding: [],
  newlyListed: [],
  topGainers: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const data = action.payload;

  switch (action.type) {
    case "LOAD_TRENDING":
      return {
        ...state,
        tranding: data,
      };
    case "LOAD_NEWLY_LISTED":
      return {
        ...state,
        newlyListed: data,
      };
    case "LOAD_TOP_GAINERS":
      return {
        ...state,
        topGainers: data,
      };
    default:
      return state;
  }
}
