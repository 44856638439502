import React from "react";
import {createRoot} from 'react-dom/client';

// ### REDUX STORE
import {Provider} from "react-redux";
import store from './store';

// ### TRANSLATION
import './localization'

// ### Google Tag Manager
import TagManager from 'react-gtm-module'

import clevertap from "clevertap-web-sdk";

// ### APP
import App from "./App";
import handleRedirectToLocation from "./utils/redirectToLocation";
import '@mdi/font/css/materialdesignicons.css';

handleRedirectToLocation();

const tagManagerArgs = {
    gtmId: 'GTM-TCRDPTV8'
}

TagManager.initialize(tagManagerArgs)

if (process.env.REACT_APP_CLEVERTAP_PROJECT_ID) {
    clevertap.spa = true;
    clevertap.init(process.env.REACT_APP_CLEVERTAP_PROJECT_ID);
    window.addEventListener('message', (event) => {
        const eventType = event.data?.type;
        
        if ((eventType === 'request_pushes' || eventType === 'loggedin') === false) {
            return;
        }
        
        if (eventType === 'request_pushes') {
            clevertap.notifications.push({
                "titleText":'Would you like to receive Push Notifications?',
                "bodyText":'We promise to only send you relevant content and give you updates on your transactions',
                "okButtonText":'Sign me up!',
                "rejectButtonText":'No thanks',
                "okButtonColor":'#f28046'
            });    
        }

        if (event.data?.user) {
            const { clevertap: clevertapConfig = null } = event.data.user;
            
            clevertap.onUserLogin.push({
                Site: {
                    Identity: clevertapConfig.identification,
                    "MSG-push": true,
                }
            });
        }
    });
}

/**
 * Render DOM
 */
const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>
);
