import {useCallback, useEffect, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

/**
 * Import meta data
 */
import metaData from './mocks/MetaDataPage.json';

const SeoMetaDataHandle = (props = { 
    title: 'Cryptodao - Crypto Exchange',
    description: 'crypto exchange',
    type: '',
    name: '@cryptodao',
}) => {
    const location = useLocation();

    // Get current page url
    let currentPageUrl = location.pathname;
    let currentPageUrlFull = window.location.href.split('?')[0];

    // Remove "/" for not main page
    if (currentPageUrl !== "/") {
        currentPageUrl = currentPageUrl.replace(/^\/+/g, '');
    }

    const pageData = metaData.find(page => page.url === currentPageUrl);

    /**
     * Render HTML, return null
     */
    return (
        <Helmet>
            { /* Standard metadata tags */}
            <title>{pageData?.title ?? props.title}</title>
            <meta name='description' content={pageData?.description ?? props.description} />
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            <meta property="og:type" content={pageData?.type ?? props.type} />
            <meta property="og:title" content={pageData?.title ?? props.title} />
            <meta property="og:description" content={pageData?.description ?? props.description} />
            { /* End Facebook tags */}
            { /* Twitter tags */}
            <meta name="twitter:creator" content={pageData?.name ?? props.name} />
            <meta name="twitter:card" content={pageData?.type ?? props.type} />
            <meta name="twitter:title" content={pageData?.title ?? props.title} />
            <meta name="twitter:description" content={pageData?.description ?? props.description} />
            { /* End Twitter tags */}
            <link rel='canonical' href={currentPageUrlFull} />
        </Helmet>
    );
}

export default SeoMetaDataHandle;
