import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import cn from "classnames";
import styles from "./Wallet.module.sass";

import Icon from "../Icon";
// import Modal from "../Modal";
// import Withdraw from "../Withdraw";
// import Deposit from "../Deposit";
// import Transfer from "../Transfer";

import LimitsMessage from "../../components/Alert/messages/LimitsMessage/LimitsMessage";
import fetchVerification from "../../store/actions/User/FetchVerification";
import fetchUserAccountInfo from "../../store/actions/User/FetchUserAccountInfo";
import { setAlert } from "../../store/actions/Alert/setAlert";
import session_manager from "../../utils/SessionManager";

const page = "wallet";

const Wallet = ({ className, children }) => {
  /**
   * @type {Dispatch<AnyAction>}
   */
  const dispatch = useDispatch();

  /**
   *
   */
  const { t } = useTranslation();

  // const { pathname } = useLocation();
  // const activeItem = navigation.find((x) => pathname.includes(x.url));

  /**
   * Get available balance from state
   */
  const availableBalance = useSelector((state) => state.user.available_balance);
  const isBalanceShown = useSelector((state) => state.user.isBalanceShown);
  const verificationData = useSelector((state) => state.user.verification_data);
  const accountInfo = useSelector((state) => state.user.account_info);
  // const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  // const [visibleTransfer, setVisibleTransfer] = useState(false);
  // const [visibleDeposit, setVisibleDeposit] = useState(false);
  // const [visibleMenu, setVisibleMenu] = useState(false);

  const blockIsActive = useSelector((state) => state.config.block_is_active);

  const navigation = useMemo(() => {
    /**
     * @type {[]}
     *
     * title, color, url, type (wallet), icon
     */
    const arr = [
      {
        title: t(`${page}.navigation.0.title`, {
          balance: ``,
        }),
        color: "#302e2e",
        url: "/wallets",
        type: "spot",
        icon: "/icons/unified.svg",
      },
    ];

    if (blockIsActive.margin_active) {
      arr.push({
        title: t(`${page}.navigation.7.title`, {
          balance: ``,
        }),
        color: "#9757D7",
        url: "/wallets/margin",
        type: "margin",
        icon: "/icons/futures.svg",
      });
    }

    arr.push({
      title: t(`${page}.navigation.2.title`, {
        balance: ``,
      }),
      color: "#FFD166",
      url: "/wallets/funding",
      separator: true,
      type: "funding",
      icon: "/icons/funding.svg",
    });

    return arr;
  }, [blockIsActive, t, availableBalance]);

  /**
   * Init store data
   */
  useEffect(() => {
    dispatch(fetchVerification());

    if (session_manager.isLoggedIn()) {
      dispatch(fetchUserAccountInfo());
    }
  }, [dispatch]);

  /**
   *
   */
  useEffect(() => {
    if (accountInfo) {
      dispatch(setAlert(false));

      if (accountInfo.profile.is_suspended) {
        dispatch(setAlert(t("alert.accountIsSuspended")));

        return;
      }
    }

    if (verificationData) {
      if (verificationData.verification.status === 2) {
        dispatch(setAlert(false));
      } else {
        if (verificationData.verification.status === 10) {
          dispatch(setAlert(t("alert.waitApprovalMessage")));
        } else {
          dispatch(setAlert(<LimitsMessage />));
        }
      }
    }

    return () => dispatch(setAlert(false));
  }, [verificationData, accountInfo]);

  /**
   * Render HTML
   */
  return (
    <>
      <div className={cn(className, styles.wallet)}>
        <div className="min-w-52 border-r dark:border-r-gray-800">
          <div
            className={cn(
              styles.group
              // , { [styles.active]: visibleMenu }
            )}
          >
            {/* <div
                            className={styles.top}
                            onClick={() => setVisibleMenu(!visibleMenu)}
                        >
                            <div className={styles.bg} style={{ backgroundColor: activeItem.color }}></div>
                            {activeItem.title}
                        </div> */}
            <div className="hidden lg:block">
              {navigation.map(
                (item, index) => (
                  // item.url ? (
                  <NavLink
                    className="flex items-center text-base gap-2 hover:bg-gray-300 py-5 px-2 dark:hover:bg-gray-800 font-semibold"
                    activeClassName={styles.active}
                    to={item.url}
                    key={index}
                    exact
                  >
                    {item.icon && <img src={item.icon} alt="coin"></img>}
                    {item.title}
                  </NavLink>
                )
                // ) : (
                //     <button
                //         className={cn(styles.item, {
                //             [styles.separator]: item.separator,
                //         })}
                //         key={index}
                //         onClick={() => setVisibleTransfer(!visibleTransfer)}
                //     >
                //         {item.icon && <Icon name={item.icon} size="20" />}
                //         {item.title}
                //     </button>
                // )
              )}
            </div>
          </div>
          {/* <div className={styles.btns}>
                        <button
                            className={cn("button button-small", styles.button)}
                            onClick={() => setVisibleDeposit(!visibleDeposit)}
                        >
                            Deposit
                        </button>
                        <button
                            className={cn("button-stroke button-small", styles.button)}
                            onClick={() => setVisibleWithdraw(!visibleWithdraw)}
                        >
                            Withdraw
                        </button>
                        <button
                            className={cn("button-stroke button-small", styles.button)}
                            onClick={() => setVisibleTransfer(!visibleTransfer)}
                        >
                            Transfer
                        </button>
                    </div> */}
        </div>
        <div className="w-full">
          <div className="sm:px-10 px-5">
            <div className="flex items-center font-semibold gap-5 lg:hidden">
              {navigation.map((item, index) => (
                <NavLink
                  className={styles.item}
                  activeClassName={styles.active}
                  to={item.url}
                  key={index}
                  exact
                >
                  <div className="flex gap-2">
                    {item.icon && <img src={item.icon}></img>}
                    {item.title}
                  </div>
                </NavLink>
              ))}
            </div>
            <div className="mt-5">
              <div className="text-2xl text-gray-400">
                {t(`${page}.totalAvailable`)}
              </div>
              <div className="flex gap-1 mt-2">
                <div className="font-semibold text-xl">
                  {isBalanceShown ? availableBalance.total.btc : "**********"}
                </div>
                <div className="text-gray-400">BTC</div>
              </div>
              <div className="text-sm font-light text-gray-400">
                ≈ ${isBalanceShown ? availableBalance.total.usdt : "******"}
              </div>
            </div>
          </div>
          <div>{children}</div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.menuTop}></div>
        </div>
      </div>
      {/* <Modal
                visible={visibleWithdraw}
                onClose={() => setVisibleWithdraw(false)}
            >
                <Withdraw
                    id='USDT'
                    onClose={() => {
                        setVisibleWithdraw(false);
                    }}
                />
            </Modal>

            <Modal
                visible={visibleDeposit}
                onClose={() => setVisibleDeposit(false)}
            >
                <Deposit id='USDT' />
            </Modal> */}

      {/*<Modal*/}
      {/*    visible={visibleTransfer}*/}
      {/*    onClose={() => setVisibleTransfer(false)}*/}
      {/*>*/}
      {/*    <Transfer*/}
      {/*        ticker=""*/}
      {/*        walletType=""*/}
      {/*        onClose={() => setVisibleTransfer(false)}*/}
      {/*    />*/}
      {/*</Modal>*/}
    </>
  );
};

export default Wallet;
