import React, { Suspense } from 'react';
import Loader from '../components/Loader';
import loaderStyles from '../assets/styles/app-loader.module.sass'

const RouteLoader = ({ children }) => {
    const isDarkMode = document.body.classList.contains('dark-mode');

    return (
        <Suspense fallback={
            <div className={isDarkMode ? loaderStyles.loader_dark : loaderStyles.loader_light}>
                <Loader />
            </div>
        }>
            {children}
        </Suspense>
    );
};

export default RouteLoader;
