import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import "./Header.css";
import styles from "./Header.module.sass";
import Image from "../../components/Image";
import Theme from "../../components/Theme";
import session_manager from "../../utils/SessionManager";
import User from "./User";
import DropdownHeader from "./Dropdown";
import Dropdown from "../../components/Dropdown";
import fetchUserAccountInfo from "../../store/actions/User/FetchUserAccountInfo";
import { setAlert } from "../../store/actions/Alert/setAlert";
import clevertap from "clevertap-web-sdk";

const page = "header";
const languagesList = {
  en: "English",
  tr: "Türkçe",
  ar: "العربية",
  th: "ไทย",
  vi: "Tiếng Việt",
  de: "Deutsch",
  el: "Ελληνικά",
  fa: "فارسی",
  fr: "Français",
  id: "Bahasa Indonesia",
  it: "Italiano",
  lt: "Lietuvių",
  ms: "Bahasa Melayu",
  nl: "Nederlands",
  pl: "Polski",
  pt: "Português",
  ru: "Русский",
  sv: "Svenska",
  tl: "Tagalog",
  uk: "Українська",
  zh_CN: "中文 (简体)",
};

const Header = ({ headerWide, ...props }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [visibleNav, setVisibleNav] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const accountInfo = useSelector((state) => state.user.account_info);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const languagesFlip = Object.fromEntries(
    Object.entries(languagesList).map(([key, value]) => [value, key])
  );

  const { search, pathname } = useLocation();
  const token = new URLSearchParams(search).get("token");
  const webview = pathname.includes("webview");
  const sign = new URLSearchParams(search).get("sign");
  const navigation = [
    {
      title: t(`${page}.navigation.0.title`),
      url: "/trading",
    },
    {
      title: t(`${page}.navigation.2.title`),
      url: "/markets",
    },
    {
      title: t(`${page}.navigation.3.title`),
      url: "/launchpad",
    },
    {
      title: t(`${page}.navigation.4.title`),
      url: "/blog",
    },
    {
      title: t("header.navigation.6.title"),
      dropdown: [
        {
          title: t("header.navigation.6.dropdown.0.title"),
          icon: "mdi mdi-account",
          url: "/career",
        },
        {
          title: t("header.navigation.6.dropdown.2.title"),
          icon: "mdi mdi-human-male-board-poll",
          url: "/leaderboard",
        },
      ],
    },
  ];

  const authButtons = (props) => {
    return (
      <div className="flex gap-1" {...props}>
        <NavLink
          className="hover:text-amber-500 rounded-md font-semibold hover:bg-gray-900 px-4 py-2 w-full text-center"
          activeClassName={styles.active}
          to="/login"
          onClick={() => setVisibleNav(false)}
        >
          {t(`${page}.authButtons.login`)}
        </NavLink>
        <NavLink
          className="bg-amber-500 hover:bg-amber-400 font-semibold px-4 py-2 rounded-md w-full text-center text-black"
          activeClassName={styles.active}
          to="/registration"
          onClick={() => setVisibleNav(false)}
        >
          {t(`${page}.authButtons.createAccount`)}
        </NavLink>
      </div>
    );
  };

  useEffect(() => {
    if (session_manager.isLoggedIn()) {
      dispatch(fetchUserAccountInfo());
    }
  }, [dispatch]);

  useEffect(() => {
    if (accountInfo) {
      dispatch(setAlert(false));

      if (accountInfo.profile.is_suspended) {
        dispatch(setAlert(t("alert.accountIsSuspended")));
      }
    }

    return () => dispatch(setAlert(false));
  }, [accountInfo]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!webview) {
    return (
      <header
        className="text-white bg-black flex items-center justify-between p-2 fixed w-full top-0 z-50 text-base h-16"
        {...props}
      >
        <div className="flex items-center">
          <NavLink
            className={styles.logo}
            to="/"
            onClick={() => setVisibleNav(false)}
          >
            <Image
              src={"/icons/logo.png"}
              srcDark={"/icons/logo.png"}
              alt="Сryptodao"
            />
          </NavLink>
          <div className={cn("ml-5 isDesktop", styles.headspllin)}></div>
          <nav
            aria-label="Main"
            data-orientation="horizontal"
            dir="ltr"
            className="Navigation_navigation__SaCR1"
          >
            <div className="Navigation_island__hx7CL">
              <div>
                <ul
                  data-orientation="horizontal"
                  className="Navigation_navbar__drXfa"
                  dir="ltr"
                >
                  {!(token && sign) && (
                    <div
                      className={cn(styles.wrap, {
                        [styles.visible]: visibleNav,
                      })}
                    >
                      <nav className="flex gap-4">
                        {navigation.map((x, index) =>
                          x.dropdown ? (
                            <DropdownHeader
                              key={index}
                              item={x}
                              setValue={setVisibleNav}
                            />
                          ) : (
                            <NavLink
                              className={cn("hover:text-amber-500")}
                              activeClassName={styles.active}
                              onClick={() => setVisibleNav(false)}
                              to={x.url}
                              key={index}
                            >
                              {x.title}
                            </NavLink>
                          )
                        )}
                      </nav>
                    </div>
                  )}
                </ul>
              </div>
            </div>
            <div className="Navigation_viewportPosition__zEjBF" />
          </nav>
        </div>
        {!(token && sign) && mobileMenuVisible && (
          <div
            className={cn(
              styles.wrap,
              "text-base relative overflow-hidden py-16 shadow-md",
              {
                [styles.visible]: mobileMenuVisible,
              }
            )}
          >
            <button
              className="absolute top-4 right-4 text-xl hover:text-amber-500 hover:scale-125"
              onClick={() => setMobileMenuVisible(false)}
            >
              <i className="mdi mdi-close"></i>
            </button>
            <div className="overflow-auto px-5">
              {session_manager.isLoggedIn() ? (
                <NavLink
                  className=""
                  activeClassName={styles.active}
                  to="/wallet-overview"
                  onClick={() => setMobileMenuVisible(false)}
                >
                  {t(`${page}.wallet`)}
                </NavLink>
              ) : (
                authButtons({ className: "flex flex-col w-full gap-4" })
              )}
              <nav className="mt-10 flex flex-col gap-4 text-base">
                {navigation.map((x, index) =>
                  x.dropdown ? (
                    <DropdownHeader
                      key={index}
                      item={x}
                      setValue={setMobileMenuVisible}
                    />
                  ) : (
                    <NavLink
                      className="hover:text-amber-500"
                      activeClassName={styles.active}
                      onClick={() => setMobileMenuVisible(false)}
                      to={x.url}
                      key={index}
                    >
                      {x.title}
                    </NavLink>
                  )
                )}
              </nav>
            </div>
          </div>
        )}
        <ul
          data-orientation="horizontal"
          className="flex items-center gap-4 px-4"
          dir="ltr"
        >
          {!session_manager.isLoggedIn() ? (
            <>
              <div className="md:flex gap-2 hidden">
                <NavLink
                  className="hover:text-amber-500 rounded-md font-semibold hover:bg-gray-900 px-4 py-2 text-center"
                  activeClassName={styles.active}
                  to="/login"
                  onClick={() => setVisibleNav(false)}
                >
                  {t(`${page}.authButtons.login`)}
                </NavLink>
                <NavLink
                  className="bg-amber-500 hover:bg-amber-400 text-black font-semibold px-4 py-2 rounded-md text-center"
                  activeClassName={styles.active}
                  to="/registration"
                  onClick={() => setVisibleNav(false)}
                >
                  {t(`${page}.authButtons.createAccount`)}
                </NavLink>
              </div>
              <Dropdown
                iconDropdown="mdi-web"
                classDropdownOptionsContainer="bg-black"
                classDropdownOption="!hover:bg-gray-800"
                value={languagesList[i18n.language]}
                setValue={(v) => {
                  localStorage.setItem("lngCode", languagesFlip[v]);
                  i18n.changeLanguage(languagesFlip[v]);

                  if (accountInfo) {
                    clevertap.event.push("language_changed", {
                      userId: accountInfo.profile.id,
                      language: v,
                      iso: languagesFlip[v],
                    });
                  }
                }}
                options={Object.values(languagesList).filter(
                  (name) => name !== null
                )}
              />
              <Theme className={cn(styles.theme_switcher)} icon />
              {!(token && sign) && (
                <>
                  <button
                    className="hover:text-amber-500 md:hidden block"
                    onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
                  >
                    <i class="mdi mdi-menu text-2xl"></i>
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              <Dropdown
                iconDropdown="mdi-web"
                iconSize="20"
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                classDropdownOptionsContainer="bg-black"
                value={languagesList[i18n.language]}
                setValue={(v) => {
                  localStorage.setItem("lngCode", languagesFlip[v]);
                  i18n.changeLanguage(languagesFlip[v]);
                }}
                options={Object.values(languagesList).filter(
                  (name) => name !== null
                )}
              />
              <NavLink
                className={cn("hover:text-amber-500")}
                activeClassName={styles.active}
                to="/wallet-overview"
                onClick={() => {
                  clevertap.event.push("asset_page_clicked", {
                    userId: accountInfo?.profile?.id,
                  });
                }}
              >
                <i className="mdi mdi-wallet text-2xl"></i>
              </NavLink>
              <User />
            </>
          )}
        </ul>
      </header>
    );
  }
};

export default Header;
