import cn from "classnames";

export default function BaseButton({ className, children, ...props }) {
  return (
    <button
      className={cn(
        className,
        "p-2 rounded-md bg-amber-500 hover:bg-amber-400 font-semibold text-base text-black disabled:bg-amber-500/50"
      )}
      {...props}
    >
      {children}
    </button>
  );
}
