import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import OutsideClickHandler from "react-outside-click-handler";

import cn from "classnames";
import styles from "./User.module.sass";

import Icon from "../../../components/Icon";
import Theme from "../../../components/Theme";

import axios from "../../../utils/Api";
import session_manager from "../../../utils/SessionManager";
import clevertap from "clevertap-web-sdk";
import LazyLoad from "react-lazyload";

const page = "header.user";

/**
 *
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const User = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const accountInfo = useSelector((state) => state.user.account_info);
  const items = [
    {
      title: t(`${page}.items.trading.title`),
      content: t(`${page}.items.trading.content`),
      icon: "mdi mdi-currency-usd",
      url: "/trading",
    },
    {
      title: t(`${page}.items.wallet.title`),
      content: t(`${page}.items.wallet.content`),
      icon: "mdi mdi-wallet",
      url: "/wallet-overview",
    },
    {
      title: t(`${page}.items.profile.title`),
      content: t(`${page}.items.profile.content`),
      icon: "mdi mdi-account",
      url: "/profile-info",
    },
    {
      title: t(`${page}.items.verification.title`),
      content: t(`${page}.items.verification.content`),
      icon: "mdi mdi-check-decagram",
      url: "/verification",
    },
    {
      title: t(`${page}.items.referrals.title`),
      content: t(`${page}.items.referrals.content`),
      icon: "mdi mdi-account-group",
      url: "/referrals",
    },
    {
      title: t(`${page}.items.twoFa.title`),
      content: t(`${page}.items.twoFa.content`),
      icon: "mdi mdi-security",
      url: "/2fa",
    },
    {
      title: t(`${page}.items.launchpad.title`),
      content: t(`${page}.items.launchpad.content`),
      icon: "mdi mdi-rocket-launch",
      url: "/launchpad",
    },

    {
      title: t(`${page}.items.fees.title`),
      content: t(`${page}.items.fees.content`),
      icon: "mdi mdi-currency-btc",
      url: "/fees",
    },
    {
      title: t(`${page}.items.darkMode.title`),
      content: t(`${page}.items.darkMode.content`),
      icon: "mdi mdi-theme-light-dark",
      darkMode: true,
    },
    {
      title: t(`${page}.items.logout.title`),
      icon: "mdi mdi-logout",
    },
  ];

  const logOut = async () => {
    // Signout clicked
    clevertap.event.push("logout_from_other_devices_clicked", {
      userId: accountInfo?.profile?.id,
    });

    await axios.post("/api/user/logout");

    session_manager.removeToken();

    history.push("/");
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(className, styles.user, { [styles.active]: visible })}>
        <button onClick={() => setVisible(!visible)}>
          <LazyLoad>
            <img
              className="w-8"
              loading="lazy"
              src="/icons/avatar.png"
              alt="Avatar"
            />
          </LazyLoad>
        </button>
        <div className={cn(styles.body, "!bg-black !p-4 z-50")}>
          <div className="text-base font-semibold">
            {items.map((x, index) =>
              x.url ? (
                <Link
                  className="flex mt-4 gap-2 hover:text-amber-500 items-center fill-white hover:fill-amber-500"
                  to={x.url}
                  onClick={() => setVisible(!visible)}
                  key={index}
                >
                  <div>
                    <i className={cn(x.icon, "text-xl")}></i>
                  </div>
                  <div className="flex flex-col !items-start">
                    <div>{x.title}</div>
                  </div>
                </Link>
              ) : x.darkMode ? (
                <div className="flex mt-4 gap-2 items-center" key={index}>
                  <div>
                    <i className={cn(x.icon, "text-xl")}></i>
                  </div>
                  <div className="flex flex-col !items-start w-full">
                    <div className="flex gap-10 items-center w-full">
                      <div>{x.title}</div>
                      <Theme icon />
                    </div>
                    {/* <div className={styles.content}>{x.content}</div> */}
                  </div>
                </div>
              ) : (
                <div
                  className="flex mt-4 gap-2 pb-4 hover:text-amber-500 items-center fill-white hover:fill-amber-500 cursor-pointer"
                  key={index}
                  onClick={logOut}
                >
                  <div>
                    <i className={cn(x.icon, "text-xl")}></i>
                  </div>
                  <div className="flex flex-col !items-start">
                    <div className="font-semibold">
                      <div>{x.title}</div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
