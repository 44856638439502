import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ListCircle from "react-ionicons/lib/ListCircle";
import Albums from "react-ionicons/lib/Albums";
import { Link, useHistory, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Slider from "react-slick";

import styles from "./AssetBalances.module.sass";

import Icon from "../../../components/Icon";
import Card from "./Card";
import List from "./List";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import Withdraw from "../../../components/Withdraw";
// import Deposit from "../../../components/Deposit";
import Transfer from "../../../components/Transfer";
import Activity from "../Activity";

import card_image from "../../../assets/images/card_image";
import Checkbox from "../../../components/Forms/Checkbox";
import axios from "../../../utils/Api";
import { fetchUserAccountSettings } from "../../../store/actions/User/FetchUserAccountInfo";
import { fetchUserBalances } from "../../../store/actions/User/FetchUserBalances";
import clevertap from "clevertap-web-sdk";
import cn from "classnames";

const page = "wallet.assetBalances";

const SORTING_FUNCTIONS = {
  ascending: (a, b, prop) => {
    if (typeof a[prop] === "string") {
      if (a[prop] > b[prop]) {
        return 1;
      }
      if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    } else {
      return a[prop] - b[prop];
    }
  },
  descending: (a, b, prop) => {
    if (typeof a[prop] === "string") {
      if (a[prop] < b[prop]) {
        return 1;
      }
      if (a[prop] > b[prop]) {
        return -1;
      }
      return 0;
    } else {
      return b[prop] - a[prop];
    }
  },
};

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const settings = {
  lazyLoad: "progressive",
  infinite: true,
  variableWidth: true,
  adaptiveHeight: true,
  slidesToScroll: 1,
  speed: 500,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
  ],

  nextArrow: (
    <SlickArrow>
      <Icon name="arrow-next" size="18" />
    </SlickArrow>
  ),
  prevArrow: (
    <SlickArrow>
      <Icon name="arrow-prev" size="18" />
    </SlickArrow>
  ),
};

/**
 *
 * @param balanceData
 * @param walletType
 * @returns {JSX.Element}
 * @constructor
 */
const AssetBalances = ({ balanceData, walletType, className, ...props }) => {
  /**
   *
   */
  const { t } = useTranslation();

  const { iso } = useParams();

  /**
   * @type {History<LocationState>}
   */
  const history = useHistory();

  /**
   * @type {Dispatch<AnyAction>}
   */
  const dispatch = useDispatch();

  /**
   * @type {string}
   */
  const storedImage = localStorage.getItem("card_image");
  const storedFilterStyles = JSON.parse(
    localStorage.getItem("card_image_styles")
  );
  const storedHideSmallBalance =
    localStorage.getItem("hideSmallBalances") || false;

  /**
   * Get data from global store
   */
  const all_icon = useSelector((state) => state.config.all_icon);
  const accountInfo = useSelector((state) => state.user.account_info);
  const verificationData = useSelector((state) => state.user.verification_data);
  const currency_data = useSelector((state) => state.config.currency_data);
  const all_currencies_flip = useSelector(
    (state) => state.config.all_currencies_flip
  );
  const smallBalances = useSelector((state) => state.user.smallBalances);
  const accountSetting = useSelector((state) => state.user.accountSettings);
  const availableBalance = useSelector((state) => state.user.available_balance);

  /**
   *
   */
  const [type, setType] = React.useState("list");
  const [currencyId, setCurrencyId] = useState("USDT");
  const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  const [visibleTransfer, setVisibleTransfer] = useState(false);
  // const [visibleDeposit, setVisibleDeposit] = useState(false);
  const [visibleHistory, setVisibleHistory] = useState(false);
  const [hideSmallBalance, setHideSmallBalance] = useState(smallBalances.hide);
  const [sorting, setSorting] = useState({
    prop: "total_balance_usdt_num",
    descending: true,
  });
  // const [sortedBalanceData, setSortedBalanceData] = useState({});

  /**
   * @type {{component: (function(*): *)}}
   */
  const verificationRequiredToast = {
    component: (text) => (
      <div>
        <p style={{ marginBottom: "8px" }}>{text}&nbsp;</p>
        <Link
          to="/verification"
          className={styles.linkToast}
          onClick={() => {
            toast.dismiss("verification_required");
          }}
        >
          {t(`wallet.verificationReqLink`)}
        </Link>
      </div>
    ),
  };

  const openHistoryModal = (id) => {
    setCurrencyId(id);
    setVisibleHistory(true);

    if (accountInfo) {
      // Spot wallet token clicked
      clevertap.event.push("spot_wallet_token_clicked", {
        userId: accountInfo.profile.id,
        CurrencyId: all_currencies_flip[id],
        Balance: balanceData[id].total_balance,
      });

      // History page clicked
      clevertap.event.push("history_page_clicked", {
        userId: accountInfo.profile.id,
      });
    }

    if (iso !== id) {
      history.push(`/wallet-overview/${id}`);
    }
  };

  const openTransferModal = (id) => {
    setCurrencyId(id);
    setVisibleTransfer(true);
  };

  /**
   *
   * @param id
   */
  const openWithdrawModal = (id) => {
    if (accountInfo) {
      clevertap.event.push("withdraw_screen_clicked", {
        userId: accountInfo.profile.id,
      });
    }

    if (verificationData?.verification?.status !== 2) {
      toast.error(
        verificationRequiredToast.component(
          t(`wallet.verificationReqWithdraw`)
        ),
        { id: "verification_required2", duration: 5000 }
      );
    } else if (
      Object.values(currency_data[all_currencies_flip[id]]?.networks ?? {})?.[0]
        ?.is_withdraw_on === false
    ) {
      toast.error(t(`wallet.disabledWithdraw`), {
        id: "disabled2",
        duration: 5000,
      });
    } else if (accountInfo.profile.is_suspended) {
      toast.error(t(`toast.errorCode.367`), {
        id: "disabled2",
        duration: 5000,
      });
    } else {
      setCurrencyId(id);
      setVisibleWithdraw((prev) => !prev);
      //history.push(`/withdraw/${id}`);
    }
  };

  const openDepositModal = (id) => {
    clevertap.event.push("deposit_now_clicked", {
      userId: accountInfo.profile.id,
      balance: `${availableBalance.total.usdt} USDT`,
    });

    if (verificationData?.verification?.status !== 2) {
      toast.error(
        verificationRequiredToast.component(t(`wallet.verificationReqDeposit`)),
        { id: "verification_required1", duration: 5000 }
      );
    } else if (
      Object.values(currency_data[all_currencies_flip[id]]?.networks ?? {})?.[0]
        ?.is_deposit_on === false
    ) {
      toast.error(t(`wallet.disabledDeposit`), {
        id: "disabled1",
        duration: 5000,
      });
    } else if (accountInfo.profile.is_suspended) {
      toast.error(t(`toast.errorCode.367`), {
        id: "disabled1",
        duration: 5000,
      });
    } else {
      history.push(`/deposit/crypto/${id}`);
    }
  };

  const handleSort = (prop) => {
    if (sorting.prop === prop) {
      setSorting((prev) => ({ prop, descending: !prev.descending }));
    } else {
      setSorting({ prop, descending: true });
    }
    // setTimeout(() => forceCheck(), 100);
    // setTimeout(() => forceCheck(), 200);
    // setTimeout(() => forceCheck(), 400);
  };

  const checkClassName = (prop) => {
    return sorting.prop === prop ? (sorting.descending ? "up" : "down") : "";
  };

  /**
   * Handle hide small balances
   */
  const handleHideSmallBalances = () => {
    const shouldHide = !hideSmallBalance;

    dispatch({
      type: "SET_HIDE_SMALL_BALANCES",
      payload: {
        hide: shouldHide,
        type: walletType,
      },
    });

    dispatch(
      fetchUserBalances({
        hide_small_balances: Number(shouldHide),
      })
    );

    setHideSmallBalance(shouldHide);

    const storingHideSmallBalances = async () => {
      localStorage.setItem("hideSmallBalances", Number(shouldHide));

      return await axios.post("/api/account/change-is-small-balances-hidden", {
        status: Number(shouldHide),
      });
    };

    storingHideSmallBalances();
  };

  /**
   *
   */
  useEffect(() => {
    dispatch(fetchUserAccountSettings());
  }, []);

  useEffect(() => {
    if (iso) {
      openHistoryModal(iso);
    }
    // console.log(iso, currencyId);
  }, [iso]);

  /**
   *
   */
  useEffect(() => {
    const hide = Boolean(
      Number(
        accountSetting
          ? accountSetting.is_small_balances_hidden
          : storedHideSmallBalance
      )
    );

    if (hide) {
      setHideSmallBalance(hide);

      dispatch({
        type: "SET_HIDE_SMALL_BALANCES",
        payload: {
          hide: hide,
          type: walletType,
        },
      });
    }
  }, [accountSetting]);

  /**
   *
   */
  useEffect(() => {
    if (!storedImage) {
      localStorage.setItem("card_image", card_image);
    }

    if (
      storedFilterStyles &&
      Object.keys(storedFilterStyles).length === Object.keys(balanceData).length
    ) {
      return;
    }

    let stylesToBeStored = {};

    for (let key in balanceData) {
      const filterStyles = {
        brightness: randomIntFromInterval(80, 120),
        hueRotate: randomIntFromInterval(0, 360),
        translate: {
          x: `-${randomIntFromInterval(0, 340)}px`,
          y: `-${randomIntFromInterval(0, 282)}px`,
        },
      };

      stylesToBeStored = {
        ...stylesToBeStored,
        [key]: filterStyles,
      };
    }

    localStorage.setItem("card_image_styles", JSON.stringify(stylesToBeStored));

    // if (balanceData) {
    //     setSortedBalanceData(
    //         Object.keys(balanceData).sort((a, b) => {
    //             const prev = (sorting.prop === 'name') ? { ...balanceData[b], name: b } : balanceData[a];
    //             const next = (sorting.prop === 'name') ? { ...balanceData[a], name: a } : balanceData[b];
    //
    //             const compareResult = SORTING_FUNCTIONS.descending(prev, next, 'total_available_num')
    //
    //             if (compareResult === 0) {
    //                 return SORTING_FUNCTIONS.descending(prev, next, 'total_available_usdt_num')
    //             }
    //
    //             return compareResult;
    //         })
    //     )
    // }
  }, [balanceData]);

  /**
   * Is null by default
   */
  if (!all_icon) {
    return <Loader />;
  }

  function sort(a, b) {
    const prev =
      sorting.prop === "name"
        ? { ...balanceData[b], name: b }
        : {
            ...balanceData[a],
            name: a,
            balance: Number(balanceData[a][sorting.prop]),
          };
    const next =
      sorting.prop === "name"
        ? { ...balanceData[a], name: a }
        : {
            ...balanceData[b],
            name: b,
            balance: Number(balanceData[b][sorting.prop]),
          };

    const sort = sorting.prop === "name" ? sorting.prop : "balance";

    let res = sorting.descending
      ? SORTING_FUNCTIONS.descending(prev, next, sort)
      : SORTING_FUNCTIONS.ascending(prev, next, sort);

    if (sorting.prop !== "name")
      if (res === 0) {
        res = SORTING_FUNCTIONS.ascending(prev, next, "name");
      }
    return res;
  }

  const header = [
    {
      title: t(`${page}.sorting.coin`),
      className: `sorting ${checkClassName("name")}`,
      onClick: () => handleSort("name"),
    },
    {
      title: t(`${page}.sorting.totalBalance`),
      className: `sorting ${checkClassName("total_balance_usdt_num")}`,
      onClick: () => handleSort("total_balance_usdt_num"),
    },
    {
      title: t(`${page}.sorting.totalAvailable`),
      className: `sorting ${checkClassName("total_available_usdt_num")}`,
      onClick: () => handleSort("total_available_usdt_num"),
    },
    {
      title: t(`${page}.sorting.frozen`),
      className: `sorting ${checkClassName("frozen_usdt_num")}`,
      onClick: () => handleSort("frozen_usdt_num"),
    },
    {
      title: t(`${page}.sorting.action`),
      className: ``,
      onClick: () => null,
    },
  ];

  return (
    <>
      <div className={cn(className)} {...props}>
        <div className="px-5 flex gap-5 items-center">
          <h2 className="text-base font-light">{t(`${page}.title`)}</h2>
          <Checkbox
            name="hide_small_balances"
            value={hideSmallBalance}
            checked={hideSmallBalance}
            classNameText="text-sm"
            onChange={() => handleHideSmallBalances()}
            content={t(`${page}.hideSmallBalances`)}
          />
        </div>
        <div className="overflow-auto mt-5 px-5">
          <table className="w-full">
            <thead className="border-b dark:border-b-gray-600">
              <tr>
                {header.map((item, index) => (
                  <th key={index}>
                    <div
                      className={cn(
                        "w-full flex",
                        index !== 4 ? "justify-start" : "justify-end"
                      )}
                    >
                      <div
                        className={cn(item.className, "py-2 font-light")}
                        onClick={item.onClick}
                      >
                        {item.title}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(balanceData)
                .sort(sort)
                .map((x, index) => {
                  return (
                    <List
                      key={index}
                      id={x}
                      icon={all_icon[x]}
                      walletType={walletType}
                      balanceData={balanceData[x]}
                      disabledButtons={{
                        deposit: !Object.values(
                          currency_data[all_currencies_flip[x]]?.networks ?? {}
                        )?.[0]?.is_deposit_on,
                        withdraw: !Object.values(
                          currency_data[all_currencies_flip[x]]?.networks ?? {}
                        )?.[0]?.is_withdraw_on,
                      }}
                      openHistoryModal={openHistoryModal}
                      openDepositModal={openDepositModal}
                      openWithdrawModal={openWithdrawModal}
                      openTransferModal={openTransferModal}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className={cn(styles.head, "mt-5")}>
        <div className={styles.headrow}>
          <p>{t(`${page}.title`)}</p>
          <Checkbox
            name="hide_small_balances"
            classNameRow={styles.checkbox}
            value={hideSmallBalance}
            checked={hideSmallBalance}
            onChange={() => handleHideSmallBalances()}
            content={t(`${page}.hideSmallBalances`)}
          />
        </div>
      </div> */}

      {/* {!Object.keys(balanceData).length ? (
        <div className={styles.not_found}>
          <h2 className={styles.info}>{t(`${page}.notFound`)}</h2>
        </div>
      ) : (
        <div className={styles.wrap}>
          {type === "cards" ? (
            <Slider
              className="cards-slider"
              {...settings}
              slidesToShow={Math.min(Object.keys(balanceData).length, 3)}
            >
              {Object.keys(balanceData).map((x, index) => {
                return (
                  <Card
                    key={index}
                    id={x}
                    icon={all_icon[x]}
                    walletType={walletType}
                    balanceData={balanceData[x]}
                    disabledButtons={{
                      deposit: !Object.values(
                        currency_data[all_currencies_flip[x]]?.networks ?? {}
                      )?.[0]?.is_deposit_on,
                      withdraw: !Object.values(
                        currency_data[all_currencies_flip[x]]?.networks ?? {}
                      )?.[0]?.is_withdraw_on,
                    }}
                    openHistoryModal={openHistoryModal}
                    openDepositModal={openDepositModal}
                    openWithdrawModal={openWithdrawModal}
                    openTransferModal={openTransferModal}
                  />
                );
              })}
            </Slider>
          ) : (
            <div className={cn(styles.trade, "overflow-auto")}>
              <div className={styles.table}>
                <div className={styles.row}>
                  <div className={styles.col}>
                    <div
                      className={`sorting ${checkClassName("name")} `}
                      onClick={() => handleSort("name")}
                    >
                      {t(`${page}.sorting.coin`)}
                    </div>
                  </div>

                  <div className={styles.col}>
                    <div
                      className={`sorting ${checkClassName(
                        "total_balance_usdt_num"
                      )}`}
                      onClick={() => handleSort("total_balance_usdt_num")}
                    >
                      {t(`${page}.sorting.totalBalance`)}
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div
                      className={`sorting ${checkClassName(
                        "total_available_usdt_num"
                      )}`}
                      onClick={() => handleSort("total_available_usdt_num")}
                    >
                      {t(`${page}.sorting.totalAvailable`)}
                    </div>
                  </div>
                  <div className={styles.col}>
                    <div
                      className={`sorting ${checkClassName("frozen_usdt_num")}`}
                      onClick={() => handleSort("frozen_usdt_num")}
                    >
                      {t(`${page}.sorting.frozen`)}
                    </div>
                  </div>

                  <div className={styles.col}>
                    <div className="flex items-center justify-end">
                      {t(`${page}.sorting.action`)}
                      <Icon name="chart" size="20" />
                    </div>
                  </div>
                </div>
                {Object.keys(balanceData)
                  .sort(sort)
                  .map((x, index) => {
                    return (
                      <List
                        key={index}
                        id={x}
                        icon={all_icon[x]}
                        walletType={walletType}
                        balanceData={balanceData[x]}
                        disabledButtons={{
                          deposit: !Object.values(
                            currency_data[all_currencies_flip[x]]?.networks ??
                              {}
                          )?.[0]?.is_deposit_on,
                          withdraw: !Object.values(
                            currency_data[all_currencies_flip[x]]?.networks ??
                              {}
                          )?.[0]?.is_withdraw_on,
                        }}
                        openHistoryModal={openHistoryModal}
                        openDepositModal={openDepositModal}
                        openWithdrawModal={openWithdrawModal}
                        openTransferModal={openTransferModal}
                      />
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      )} */}

      {/** Modals **/}
      <Modal
        visible={visibleWithdraw}
        onClose={() => {
          setVisibleWithdraw(false);
        }}
      >
        <Withdraw
          id={currencyId}
          onClose={() => {
            setVisibleWithdraw(false);
          }}
        />
      </Modal>

      {/* <Modal
                visible={visibleDeposit}
                onClose={() => {
                    setVisibleDeposit(false);
                }}
            >
                <Deposit id={currencyId} />
            </Modal> */}

      <Modal
        outerClassName={styles.history}
        visible={visibleHistory}
        onClose={() => {
          setVisibleHistory(false);
          history.push(`/wallet-overview`);
        }}
      >
        <Activity id={currencyId} />
      </Modal>
      <Modal
        disableOutsideClose={true}
        visible={visibleTransfer}
        onClose={() => {
          setVisibleTransfer(false);
        }}
      >
        <Transfer
          ticker={currencyId}
          walletType={walletType}
          onClose={() => setVisibleTransfer(false)}
        />
      </Modal>
    </>
  );
};

export default AssetBalances;
