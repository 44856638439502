import { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, Link } from "react-router-dom";
import "./Footer.css";
import Image from "../../components/Image";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import LogoAppleAppstore from "react-ionicons/lib/LogoAppleAppstore";
import LogoGooglePlaystore from "react-ionicons/lib/LogoGooglePlaystore";
import parse from "html-react-parser";
import clevertap from "clevertap-web-sdk";
import LazyLoad from "react-lazyload";

const page = "footer";
const Footer = () => {
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });
  const [visible, setVisible] = useState(false);
  const [email, setEmail] = useState("");
  const accountInfo = useSelector((state) => state.user.account_info);
  const { t } = useTranslation();

  const services = [
    {
      title: t(`${page}.services.1`),
      to: "/help-center",
    },
    {
      title: t(`${page}.services.2`),
      to: "/legal/terms-and-conditions",
    },
    {
      title: t(`${page}.services.3`),
      to: "/faq",
    },
  ];

  const company = [
    {
      title: t(`${page}.menu.0`),
      url: "/trading",
    },
    {
      title: t(`${page}.menu.1`),
      url: "/markets",
    },
    {
      title: t(`${page}.menu.2`),
      url: "/career",
    },
    {
      title: t(`${page}.menu.3`),
      url: "/proof-of-reserves",
    },
    {
      title: t(`${page}.menu.4`),
      url: "/fees",
    },
  ];

  const terms = [
    {
      title: t(`${page}.terms.0`),
      url: "/legal/terms-and-conditions",
    },
    {
      title: t(`${page}.terms.1`),
      url: "/legal/privacy-policy",
    },
    {
      title: t(`${page}.terms.2`),
      url: "/legal/complaints-policy",
    },
  ];

  const mobileMenu = [
    {
      title: t(`${page}.mobileMenu.0`),
      icon: "Home",
      url: "/",
    },
    {
      title: t(`${page}.mobileMenu.1`),
      icon: "Markets",
      url: "/markets",
    },
    {
      title: t(`${page}.mobileMenu.2`),
      icon: "Trading",
      url: "/trading",
    },
    {
      title: t(`${page}.mobileMenu.3`),
      icon: "Wallet",
      url: "/wallet-overview",
    },
  ];

  const { search, pathname } = useLocation();
  const token = new URLSearchParams(search).get("token");
  const sign = new URLSearchParams(search).get("sign");
  const webview = pathname.includes("webview");
  const isHomePage = pathname === "/";
  const socials = [
    {
      src: "/icons/facebook.svg",
      alt: "facebook",
    },
    {
      src: "/icons/x.svg",
      alt: "x",
    },
    {
      src: "/icons/instagram.svg",
      alt: "instagram",
    },
    {
      src: "/icons/youtube.svg",
      alt: "youtube",
    },
    {
      src: "/icons/linkedin.svg",
      alt: "linkedin",
    },
    {
      src: "/icons/telegram.svg",
      alt: "telegram",
    },
    {
      src: "/icons/tiktok.svg",
      alt: "tiktok",
    },
    {
      src: "/icons/reddit.svg",
      alt: "reddit",
    },
    {
      src: "/icons/discord.svg",
      alt: "discord",
    },
  ];

  const community = [
    {
      title: t(`${page}.companyMenu.0`),
      to: "/referral",
    },
    {
      title: "Vip",
      to: "/vip-info",
    },
    {
      title: "Contact Us",
      to: "/contact",
    },
  ];

  if (!webview) {
    return (
      <footer className="py-10 px-5">
        <div className="flex lg:gap-20 gap-5 justify-center flex-wrap lg:flex-row flex-col">
          <div className="flex flex-col gap-5">
            <div className="bg-black max-w-fit p-2 rounded-sm">
              <img src="/icons/logo.png" alt="logo" />
            </div>
            <div className="flex gap-5 flex-wrap lg:max-w-40 max-w-full">
              {socials.map((item) => (
                <a href="/" key={item.alt}>
                  <img
                    src={item.src}
                    alt={item.alt}
                    width={25}
                    height={25}
                  ></img>
                </a>
              ))}
            </div>
          </div>
          <div className="lg:flex gap-20 hidden">
            <div>
              <h6 className="font-bold text-amber-400 text-xl">
                {t(`${page}.servicesInfo`)}
              </h6>
              <div className="flex flex-col mt-2 gap-2">
                {services.map((x, index) => (
                  <Fragment key={index}>
                    {x.to ? (
                      <Link
                        className="hover:text-amber-400 text-base font-light"
                        to={x.to}
                        onClick={x?.onClick}
                      >
                        {x.title}
                      </Link>
                    ) : (
                      <a
                        className="hover:text-amber-400 text-base font-light"
                        href={x.url}
                        onClick={x?.onClick}
                      >
                        {x.title}
                      </a>
                    )}
                  </Fragment>
                ))}
              </div>
            </div>
            <div>
              <h6 className="font-bold text-amber-400 text-xl">
                {t(`${page}.companyInfo`)}
              </h6>
              <div className="flex flex-col mt-2 gap-2">
                {company.map((x, index) => (
                  <Link
                    className="hover:text-amber-400 text-base font-light"
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </Link>
                ))}
              </div>
            </div>
            <div>
              <h6 className="font-bold text-amber-400 text-xl">
                {t(`${page}.communityInfo`)}
              </h6>
              <div className="flex flex-col mt-2 gap-2">
                {community.map((item, index) => (
                  <Link
                    className="hover:text-amber-400 text-base font-light"
                    to={item.to}
                    key={index}
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="lg:hidden flex flex-col gap-5">
            <div className="relative">
              <label
                className="hover:text-amber-500 cursor-pointer"
                htmlFor="q1"
              >
                <h6 className="font-semibold text-xl">
                  {t(`${page}.servicesInfo`)}
                </h6>
              </label>
              <input id="q1" className="hidden peer" type="checkbox" />
              <div className="peer-checked:flex flex-col gap-2 hidden shadow-md p-5">
                {services.map((x, index) => (
                  <Fragment key={index}>
                    {x.to ? (
                      <Link
                        className="hover:text-amber-400 text-base font-light"
                        to={x.to}
                        onClick={x?.onClick}
                      >
                        {x.title}
                      </Link>
                    ) : (
                      <a
                        className="hover:text-amber-400 text-base font-light"
                        href={x.url}
                        onClick={x?.onClick}
                      >
                        {x.title}
                      </a>
                    )}
                  </Fragment>
                ))}
              </div>
              <label
                className="text-2xl peer-checked:rotate-180 absolute top-0 right-0"
                htmlFor="q1"
              >
                <i className="mdi mdi-chevron-down"></i>
              </label>
            </div>
            <div className="relative">
              <label
                className="hover:text-amber-500 cursor-pointer"
                htmlFor="q2"
              >
                <h6 className="font-semibold text-xl">
                  {t(`${page}.companyInfo`)}
                </h6>
              </label>
              <input id="q2" className="hidden peer" type="checkbox" />
              <div className="peer-checked:flex flex-col gap-2 hidden shadow-md p-5">
                {company.map((x, index) => (
                  <Link
                    className="hover:text-amber-400 text-base font-light"
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </Link>
                ))}
              </div>
              <label
                className=" text-2xl peer-checked:rotate-180 absolute top-0 right-0"
                htmlFor="q2"
              >
                <i className="mdi mdi-chevron-down"></i>
              </label>
            </div>
            <div className="relative">
              <label
                className="cursor-pointer hover:text-amber-500"
                htmlFor="q3"
              >
                <h6 className="font-semibold text-xl">
                  {t(`${page}.communityInfo`)}
                </h6>
              </label>
              <input id="q3" className="hidden peer" type="checkbox" />
              <div className="peer-checked:flex flex-col gap-2 hidden shadow-md p-5">
                {community.map((item, index) => (
                  <Link
                    className="hover:text-amber-400 text-base font-light"
                    to={item.to}
                    key={index}
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
              <label
                className="text-2xl absolute top-0 right-0 peer-checked:rotate-180"
                htmlFor="q3"
              >
                <i className="mdi mdi-chevron-down"></i>
              </label>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
