import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { FocusError } from "focus-formik-error";
import * as Yup from "yup";

import cn from "classnames";
import styles from "./Withdraw.module.sass";

import TextInput from "../Forms/TextInput";
import Confirm from "./Confirm";
import axios from "../../utils/Api";
import TraderPrepare, { formatNum } from "../../utils/TraderPrepare";
import { useHistory } from "react-router";
import { getUserBalances } from "../../store/utils/GetUserBalances";
import { useTranslation } from "react-i18next";
import { fetchAddressBook } from "../../store/actions/User/FetchUserAccountInfo";
import fetchVerification from "../../store/actions/User/FetchVerification";
import { Link, useLocation, useParams } from "react-router-dom";
import AddressBookDropdown from "./AddressBookDropdown/AddressBookDropdown";
import Dropdown from "../Dropdown";
import fetchActiveCurrenciesConfig from "../../store/actions/Config/ActiveCurrencies";
import { setAlert } from "../../store/actions/Alert/setAlert";
import Loader from "../Loader";
import ConfirmTwoFaCodeFields from "../ConfirmTwoFaCodeFields";
import Modal from "../Modal";
import clevertap from "clevertap-web-sdk";

/**
 * Network Types
 *
 * @type {{}}}
 */
const items = {
  0: {
    network: 0,
    name: "Mainnet",
    iso3: "Main",
  },
  1: {
    network: 1,
    name: "ERC 20",
    iso3: "ETH",
  },
  2: {
    network: 2,
    name: "TRC 20",
    iso3: "TRX",
  },
  3: {
    network: 3,
    name: "BNB",
    iso3: "BNB",
  },
  4: {
    network: 4,
    name: "BEP 20",
    iso3: "BSC",
  },
  5: {
    network: 5,
    name: "BEP 20 TESTNET",
    iso3: "BSC",
  },
};

const page = "wallet.withdraw";

const Withdraw = ({ id: idInit, onClose }) => {
  let initialRender = true;
  let sentRequest = false;
  const hideTotal = true;

  /**
   * Translation
   */
  const { t } = useTranslation();

  /**
   * History object
   *
   * @type {ServerContextJSONValue}
   */
  const history = useHistory();

  /**
   * @type {Dispatch<AnyAction>}
   */
  const dispatch = useDispatch();

  /**
   * Get ISO3 from url or props
   */
  const { currency_iso3 } = useParams();
  const iso3 =
    currency_iso3 !== undefined ? currency_iso3.toUpperCase() : idInit;
  const [id] = useState(iso3);

  /**
   * Init local state
   */
  // const [save, setSave] = useState(true);
  const [sendToType, setSendToType] = useState(0);
  const [visibleWithdraw, setVisibleWithdraw] = useState(true);
  const [priceUsdt, setPriceUsdt] = useState(0);
  const [feeUsdt, setFeeUsdt] = useState(0);
  const [fee, setFee] = useState(0);
  const [amountUsdt, setAmountUsdt] = useState(0);
  let [totalError, setTotalError] = useState(null);
  const [addressDataChangeDisabled, setAddressDataChangeDisabled] =
    useState(false);
  const [visibleCoinError, setVisibleCoinError] = useState(false);
  const [visibleNetworkError, setVisibleNetworkError] = useState(false);

  /**
   * Get data from state
   */
  const accountInfo = useSelector((state) => state.user.account_info);
  const verificationData = useSelector((state) => state.user.verification_data);
  // const settings_withdraw = useSelector(state => state.config.settings_withdraw);
  // const all_currency = useSelector(state => state.config.all_currencies);
  const all_currency_flip = useSelector(
    (state) => state.config.all_currencies_flip
  );

  // const userWithdrawLimit = `${(verificationData?.statuses?.personal?.approved && verificationData?.statuses?.address?.approved) && (+settings_withdraw.limits.personal_address_verified.max).toLocaleString("en-US") ||
  // (verificationData?.statuses?.personal?.approved) && (+settings_withdraw.limits.personal_full_name_verified.max).toLocaleString("en-US")
  // || settings_withdraw.limits.no_personal_verification.max
  // }`;

  const white_list_status = accountInfo?.security?.white_list_status;
  const activeCurrencyData = useSelector(
    (state) => state.config.active_currencies?.[all_currency_flip[id]]
  );

  /**
   * Get url params
   *
   * @type {Location<LocationState>}
   */
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  /**
   *
   */
  const [orderData, setOrderData] = useState({
    image: "",
    isVisible: false,
    id: null,
  });

  useEffect(() => {
    dispatch(fetchActiveCurrenciesConfig());
    dispatch(fetchVerification());
    dispatch(fetchAddressBook());
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (accountInfo) {
      dispatch(setAlert(false));

      if (accountInfo.profile.is_suspended) {
        dispatch(setAlert(t("alert.accountIsSuspended")));

        return;
      }
    }

    if (verificationData) {
      if (verificationData.verification.status === 2) {
        dispatch(setAlert(false));
      } else {
        if (verificationData.verification.status === 10) {
          dispatch(setAlert(t("alert.waitApprovalMessage")));
        } else {
          dispatch(
            setAlert(
              verificationRequiredToast.component(
                t(`wallet.verificationReqWithdraw`)
              )
            )
          );
        }
      }
    }

    return () => dispatch(setAlert(false));
  }, [verificationData, accountInfo]);

  /**
   * Get data from state
   *
   * @type {string|*}
   */
  const tickerCommission = useSelector((state) => state.config.commission[id]);
  const balanceData = useSelector((state) =>
    getUserBalances(state, id, "spot")
  );
  const nameId = useSelector((state) => state.config.nice_ccy_names[id]);
  const tickerPair = useSelector((state) =>
    state.ticker.pairs.find((pair) => pair.main.iso3 === id)
  );

  const tickerDecimalRounding = useSelector((state) => {
    const tickerId = state.config.all_currencies_flip[id];

    if (tickerId !== undefined) {
      return state.config.currency_data[tickerId].decimal_rounding;
    }

    return 0;
  });

  /**
   *
   */
  const defaultNetworkType = tickerCommission
    ? tickerCommission.network_type
    : null;
  const [network, setNetwork] = useState(null);

  /**
   *
   * @type {}
   */
  const options = tickerCommission
    ? Object.values(items).filter(
        (type) =>
          tickerCommission.networks.some(
            (x) => x.network_type === type.network
          ) || type.network === defaultNetworkType
      )
    : [];

  /**
   * @type {{}}
   */
  const coinWithdrawDataConverted = {
    null: {},
  };

  if (tickerCommission) {
    [tickerCommission, ...(tickerCommission.networks ?? [])].forEach(
      (item) =>
        (coinWithdrawDataConverted[item.network_type] = {
          name: items[item.network_type]?.name,
          min_commission: item.min_commission,
          min_withdraw: item.min_withdraw,
          percent: item.percent,
          fixed: item.fixed,
        })
    );
  }

  /**
   * Prepare addresses book list
   * @type {*[]}
   */
  let addressBookList = [];
  const addresses = accountInfo?.address_book;
  if (addresses !== undefined) {
    const networksKeys = Object.keys(coinWithdrawDataConverted);

    addressBookList = addresses.filter(function (obj) {
      return networksKeys.includes(obj.network_type.toString());
    });
  }

  const amountInput = useRef(null);
  const totalInput = useRef(null);

  const has_tag = activeCurrencyData?.networks?.[network]?.has_tag;

  const [networkData, setNetworkData] = useState(
    coinWithdrawDataConverted[network]
  );

  /**
   * @type {{component: (function(*): *)}}
   */
  const verificationRequiredToast = {
    component: (text) => (
      <div>
        <p style={{ marginBottom: "8px" }}>{text}&nbsp;</p>
        <Link
          to="/verification"
          className={styles.linkToast}
          onClick={() => {
            toast.dismiss("verification_required");
          }}
        >
          {t(`wallet.verificationReqLink`)}
        </Link>
      </div>
    ),
  };

  const inputCalculate = () => {
    const amount = formik.values.amount - fee;

    return +TraderPrepare.formatNumCurrency(
      amount > 0
        ? Number(amount).toFixed(activeCurrencyData.decimal_rounding)
        : 0,
      tickerDecimalRounding ?? 8,
      0,
      true
    );
  };

  const ruleValidationAmount = useMemo(() => {
    return Yup.number(t(`${page}.form.errors.amount.num`))
      .min(
        coinWithdrawDataConverted[network]?.min_withdraw ?? 0,
        `${t(`${page}.form.errors.amount.minAmount`)}: >=${
          coinWithdrawDataConverted[network]?.min_withdraw ?? 0
        } ${id}`
      )
      .required(t(`${page}.form.errors.amount.req`));
  }, [coinWithdrawDataConverted, network]);

  const formik = useFormik({
    initialValues: {
      address: "",
      tag: "",
      // email: '',
      amount: "",
      network: null,
      iban: "",
    },
    validationSchema: Yup.object().shape({
      address: Yup.string().when("sendToType", {
        is: 0,
        then: Yup.string()
          .min(10, t(`${page}.form.errors.address.min`))
          .required(t(`${page}.form.errors.address.req`)),
      }),
      tag: Yup.string().when("address", {
        is: () => has_tag,
        then: Yup.string().required(t(`${page}.form.errors.tag.req`)),
      }),
      email: Yup.string().email(t(`${page}.form.errors.email.email`)),
      iban: Yup.string().when("sendToType", {
        is: 1,
        then: Yup.string()
          .min(10, t(`${page}.form.errors.iban.min`))
          .required(t(`${page}.form.errors.iban.req`)),
      }),
      amount: ruleValidationAmount,
    }),
    validateOnChange: true,
    onSubmit: async (values) => {
      // console.log('4. formik onsubmit', values);
      sentRequest = true;

      if (!totalInput.current && currency_iso3 === undefined) {
        toast.error(t(`${page}.form.errors.total.ref`));
        return;
      }

      // If cryptocurrency
      if (sendToType === 0) {
        const response = await axios({
          method: "POST",
          url: "/api/withdraw/index",
          data: {
            iso: id,
            amount: String(formik.values.amount), // inputCalculate(),
            fee_from_amount: 1,
            to_address: values.address,
            network_type:
              currency_iso3 === undefined ? network : values.network,
            comment: has_tag ? String(values.tag) : undefined,
          },
        });

        const data = response.data;

        if (response.data.status) {
          setVisibleWithdraw(false);

          setOrderData({
            image: data.data.image,
            isVisible: true,
            id: data.data.id,
          });
        } else {
          recalculateForm();

          if (data) {
            // console.log(data);
            if (data.code === 117) {
              toast.error(`${t(`${page}.form.errors.submit.cannotWithdraw`)}`);
              return;
            }

            if (data.code === 120) {
              toast.error(
                `${t(`${page}.form.errors.submit.wait`)} ${
                  data.data.timer
                } sec.`
              );
              return;
            }

            if (data.code === 366) {
              const time_left = data.data.time_left;

              const hours = Math.floor(time_left / 3600); // Calculate hours
              const minutes = Math.floor((time_left % 3600) / 60); // Calculate minutes

              const formatHours = hours < 10 ? `0${hours}` : hours;
              const formatMinutes = minutes < 10 ? `0${minutes}` : minutes;

              toast.error(
                t(`${page}.form.errors.submit.recentWhitelist`, {
                  time: `${formatHours}:${formatMinutes}`,
                })
              );
              return;
            }

            if (data.code === 367) {
              toast.error(t("toast.errorCode.367"));
              return;
            }

            if (data.code === 369) {
              toast.error(
                verificationRequiredToast.component(
                  t(`wallet.verificationReqWithdraw`)
                ),
                { id: "verification_required", duration: 5000 }
              );

              return;
            }

            if (data.code === 26) {
              toast.error(data.errors[0]);

              return;
            }

            if (data.code === 21) {
              toast.error(t("toast.errorCode.21"));

              formik.setFieldError("amount", t("toast.errorCode.21"));

              return;
            }

            if (data.code === -1) {
              toast.error(
                typeof data.errors[0] === "object"
                  ? Object.values(data.errors[0]).join("\n\n")
                  : data.errors[0]
              );

              clevertap.event.push("insufficient_balance_deposit", {
                userId: accountInfo?.profile?.id,
              });

              return;
            }

            if (
              data.errors[0] === "Current address is not in your white list"
            ) {
              toast.error(
                <span>
                  {t(`${page}.form.errors.submit.whitelist.0`)}
                  <br /> {t(`${page}.form.errors.submit.whitelist.1`)}{" "}
                  <Link className={styles.link} to="/address-management">
                    {t(`${page}.form.errors.submit.whitelist.2`)}
                  </Link>{" "}
                  {t(`${page}.form.errors.submit.whitelist.3`)}
                </span>
              );
            } else {
              if (!data.code) {
                toast.error(
                  typeof data.errors[0] === "object"
                    ? Object.values(data.errors[0]).join("\n\n")
                    : data.errors[0]
                );
              }
            }
          }
        }
      } else {
        // If fiat currency
        const response = await axios({
          method: "POST",
          url: "/api/fiat/withdraw",
          data: {
            payment: "bank_payment____14",
            amount: String(formik.values.amount),
            wallet: formik.values.iban,
            currency: id,
          },
        });

        const data = response.data;

        if (data.status) {
          toast.success(data.message);

          setVisibleWithdraw(false);

          setOrderData({
            isVisible: true,
            id: data.data.id,
            image: data.data.image,
          });
        } else {
          if (data.errors[0]) {
            toast.error(data.errors[0]);
          }
        }
      }
    },
  });

  const handleSwitchAddress = (addressData) => {
    formik.setFieldValue("address", addressData.address);
    formik.setFieldError("address", "");

    if (has_tag) {
      formik.setFieldValue("tag", addressData.tag);
      formik.setFieldError("tag", "");
    }

    setNetworkData(coinWithdrawDataConverted[addressData.network_type]);

    setTimeout(() => {
      formik.validateForm();
    });

    if (typeof addressData.network_type === "number") {
      setNetwork(addressData.network_type);
      setAddressDataChangeDisabled(true);
    } else {
      setAddressDataChangeDisabled(false);
    }
  };

  const handleSetAmount = (amount = null) => {
    formik.values.amount =
      amount !== null
        ? amount
        : TraderPrepare.fixedFromInt(balanceData.total_available_num, 8);
    formik.setFieldError("amount", "");

    if (amountInput.current) {
      amountInput.current.value = formik.values.amount;
    }

    amountChanged();
  };

  const amountChanged = () => {
    if (!amountInput.current) {
      return;
    }

    handleChange("amountInput");
  };

  const totalChanged = (e) => {
    // totalInput.current.value = e.target.value;
    handleChange("totalInput");
  };

  const normalizeNumber_BKP = (value) => {
    let endDot = false;

    if (typeof value === "string") {
      const charAtEnd = value.charAt(value.length - 1);

      if (charAtEnd === "0" && value.charAt(value.length - 2) === "0") {
        return value.slice(0, value.indexOf(".") + 8);
      }

      endDot = charAtEnd === ".";
      if (!endDot) {
        return value.slice(0, value.indexOf(".") + 9);
      }
    }

    return (
      (+value || 0).toFixed(8).replace(/(\.)?0{1,}$/, "") + (endDot ? "." : "")
    );
  };

  const normalizeNumber = (value) => {
    let endDot = false;

    if (typeof value === "string") {
      // If the first character is a dot, add 0 before the dot
      if (value.charAt(0) === ".") {
        value = "0" + value;
      }

      if (value.charAt(0) === "0" && value.length > 1 && !value.includes(".")) {
        value = "0." + value.slice(1);
      }

      const charAtEnd = value.charAt(value.length - 1);
      const charBeforeEnd = value.charAt(value.length - 2);

      if (
        charAtEnd === "0" &&
        (charBeforeEnd === "0" || charBeforeEnd === ".")
      ) {
        const indexOfDot = value.indexOf(".");

        if (indexOfDot === -1 || indexOfDot > 0) {
          // Добавляем десятичную точку после первого нуля
          const newValue = value.replace(/^0+/, (match) =>
            match.length > 1 && !value.includes(".") ? "0." : "0"
          );

          // Если точка уже была, обрезаем после 8 знаков
          return newValue.includes(".")
            ? newValue.slice(0, indexOfDot + 9).toString()
            : newValue;
        }
      }

      endDot = charAtEnd === ".";
      if (!endDot && charBeforeEnd === "0") {
        return value.slice(0, value.indexOf(".") + 9).toString();
      }
    }

    return (
      (+value || 0)
        .toFixed(8)
        .replace(/(\.)?0{1,}$/, "")
        .toString() + (endDot ? "." : "")
    );
  };

  const handleChange = (inpType) => {
    if (!amountInput.current) {
      return;
    }

    if (!totalInput.current) {
      return;
    }

    if (!Object.values(networkData).length) {
      setNetworkData(coinWithdrawDataConverted[formik.values.network]);
    }

    if (inpType === "totalInput") {
      if (totalInput.current.value !== "" && !isNaN(totalInput.current.value)) {
        totalInput.current.value = normalizeNumber(totalInput.current.value);
      } else {
        setPriceUsdt(0);
      }

      if (+totalInput.current.value <= 0) {
        amountInput.current.value = 0;
      } else {
        // amountInput.current.value = (+networkData.percent !== 1 && ((+totalInput.current.value || 0) + +networkData.fixed) / ((1 - +networkData.percent || 1)) || 0);
        amountInput.current.value = +totalInput.current.value || 0;

        if (+amountInput.current.value < 0) {
          amountInput.current.value = 0;
        }
      }

      amountInput.current.value = formatNum(amountInput.current.value, true);
      formik.values.amount = amountInput.current.value;
    }

    if (amountInput.current.value !== "" && !isNaN(amountInput.current.value)) {
      amountInput.current.value = normalizeNumber(amountInput.current.value);
      formik.values.amount = amountInput.current.value;

      //formik.values.amount = normalizeNumber(amountInput.current.value);
    }

    const feeCalculated =
      (+amountInput.current.value || 0) * +networkData?.percent +
      +networkData?.fixed;

    setFeeUsdt(
      TraderPrepare.formatNumCurrency(
        feeCalculated * (tickerPair ? tickerPair.rate : 100000000)
      )
    );

    setFee(
      normalizeNumber(Math.max(networkData?.min_commission, feeCalculated))
    );

    setAmountUsdt(
      TraderPrepare.formatNumCurrency(
        amountInput.current.value * (tickerPair ? tickerPair.rate : 100000000)
      )
    );

    if (feeCalculated >= networkData?.min_commission) {
      setTotalError(null);
    }

    if (inpType === "totalInput") {
      if (feeCalculated < networkData?.min_commission) {
        if (+totalInput.current.value <= networkData.min_commission) {
          amountInput.current.value = 0;
          formik.values.amount = 0;
          setTotalError(
            `${t(`${page}.form.errors.total.lessThan`)} (${
              networkData.min_commission
            })`
          );
          return;
        }

        setTotalError(null);

        amountInput.current.value = formatNum(
          +totalInput.current.value + networkData.min_commission,
          true
        );
        formik.values.amount = amountInput.current.value;
      }

      setPriceUsdt(
        +totalInput.current.value * (tickerPair ? tickerPair.rate : 100000000)
      );
      return;
    }

    setTotalError(null);

    const calculatedTotal = +amountInput.current.value - +fee;
    totalInput.current.value = +calculatedTotal < 0 ? 0 : calculatedTotal;
    totalInput.current.value = formatNum(totalInput.current.value, true);

    setPriceUsdt(
      +totalInput.current.value * (tickerPair ? tickerPair.rate : 100000000)
    );
  };

  /**
   * Switch network
   *
   * @param networkType
   */
  const handleSwitchNetwork = (networkType) => {
    const networkData = coinWithdrawDataConverted[networkType];

    // Clear current address
    if (network !== null) {
      formik.setFieldValue("address", "");
      formik.setFieldError("address", "");
    }

    setNetworkData(networkData);
    setNetwork(networkType);

    formik.setFieldError("amount", "");

    setTimeout(() => {
      formik.validateForm();
    });
  };

  /**
   * Clear order data and close
   */
  const onCloseConfirmWindow = (requiredFields) => {
    const orderId = orderData.id;

    setOrderData({
      image: "",
      isVisible: false,
      id: null,
    });

    // Do close main window
    if (currency_iso3 === undefined) {
      onClose();
    }

    history.push({
      pathname: "/link-confirmation/confirm",
      state: {
        defaultOrderId: orderId,
        requiredFields,
        coinType: activeCurrencyData.type,
      },
    });
  };

  /**
   * Show Form
   *
   * @returns {Element}
   */
  const showForm = () => {
    return (
      <>
        {visibleWithdraw && (
          <div className={styles.withdraw}>
            <form onSubmit={formik.handleSubmit}>
              <FocusError formik={formik} />

              <div className="text-2xl font-semibold">
                {t(`${page}.title`)} {id}
              </div>
              {/* <h2 style={{ textAlign: 'center' }}>{t(`${page}.sendTo`)}</h2>
                            <div className={styles.btns}>
                                {[t(`${page}.sendToOptions.address`), t(`${page}.sendToOptions.fiat`), t(`${page}.sendToOptions.email`)].map((x, index) => (
                                    <button
                                        className={cn("button-small", styles.button, (sendToType === index) ? styles.active : styles.button)}
                                        key={x}
                                        onClick={() => setSendToType(index)}
                                        type="button"
                                    >
                                        {x}
                                    </button>
                                ))}
                            </div> */}

              {sendToType === 0 ? (
                <>
                  {white_list_status ? (
                    <div className={styles.dropdownContainer}>
                      <AddressBookDropdown
                        className={styles.dropdown}
                        classDropdownHead={cn(
                          styles.dropdownHead,
                          formik.errors.address && styles.red
                        )}
                        classDropdownBody={styles.dropdownBody}
                        classDropdownOption={styles.dropdownOption}
                        value={
                          formik.values.address ??
                          "Choose address from whitelist"
                        }
                        label={t(`${page}.form.label.address`)}
                        setValue={handleSwitchAddress}
                        options={addressBookList}
                        coin={id}
                      />
                      {formik.errors.address ? (
                        <div className={styles.error}>
                          {formik.errors.address}
                        </div>
                      ) : null}
                      <div className={styles.note}>
                        {`${t(`${page}.form.note.address.0`)} ${nameId} ${t(
                          `${page}.form.note.address.1`
                        )}
                                            ${networkData?.name} ${t(
                          `${page}.form.note.address.2`
                        )}`}
                      </div>
                    </div>
                  ) : (
                    <TextInput
                      className="mt-4"
                      label={t(`${page}.form.label.address`)}
                      name="address"
                      type="text"
                      note={`${t(`${page}.form.note.address.0`)} ${nameId} ${t(
                        `${page}.form.note.address.1`
                      )} ${networkData?.name} ${t(
                        `${page}.form.note.address.2`
                      )}`}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      error={formik.errors.address ?? ""}
                      required
                    />
                  )}
                </>
              ) : sendToType === 1 ? (
                <>
                  <TextInput
                    className="mt-4"
                    label={t(`${page}.form.label.iban`)}
                    name="iban"
                    type="text"
                    value={formik.values.iban}
                    onChange={formik.handleChange}
                    error={formik.errors.iban ?? ""}
                  />
                </>
              ) : (
                <>
                  <TextInput
                    className="mt-4"
                    label={t(`${page}.form.label.email`)}
                    name="email"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.errors.email ?? ""}
                    // required
                  />
                </>
              )}

              {/* <Checkbox
                                name="save_address"
                                value={save}
                                className={styles.checkbox}
                                onChange={() => setSave(!save)}
                                content="Save address for next time"
                                /> */}

              {sendToType === 0 && (
                <div className="mt-4">
                  <Dropdown
                    className={styles.dropdown}
                    classDropdownHead={styles.dropdownHead}
                    classDropdownBody={styles.dropdownBody}
                    classDropdownOption={styles.dropdownOption}
                    disabled={addressDataChangeDisabled}
                    value={options?.find((x) => x.network === network)?.name}
                    label={t(`${page}.network`)}
                    setValue={(v) =>
                      handleSwitchNetwork(
                        options.find((x) => x.name === v).network
                      )
                    }
                    options={options.map((x) => x.name)}
                    coin={id}
                    required
                  />
                </div>
              )}

              {has_tag && (
                <TextInput
                  className="mt-4"
                  label={t(`${page}.form.label.tag`)}
                  name="tag"
                  type="text"
                  // note={`$${userWithdrawLimit}/$${userWithdrawLimit} ${t(`${page}.daily`)}.`}
                  value={formik.values.tag}
                  onChange={formik.handleChange}
                  disabled={addressDataChangeDisabled}
                  error={formik.errors.tag ?? ""}
                  // numberValidated={true}
                  onWrongPaste={() => toast.error()}
                />
              )}

              <div className="relative mt-4">
                <TextInput
                  label={t(`${page}.form.label.amount`)}
                  name="amount"
                  type="number"
                  note={
                    `≈ ${amountUsdt} USDT` +
                    ` (${t(`${page}.form.note.minAmount`)}: ${
                      networkData?.min_withdraw ?? 0
                    } ${id})`
                  }
                  ref={amountInput}
                  value={formik.values.amount}
                  onChange={(e) => {
                    formik.handleChange(e);
                    amountChanged();
                  }}
                  error={formik.errors.amount ?? ""}
                  numberValidated={true}
                  onWrongPaste={() => toast.error()}
                  autoComplete="off"
                  required
                />
                <button
                  className="absolute right-0 bottom-8 mr-2 bg-amber-500 hover:bg-amber-400 p-1 rounded-md font-light"
                  type="button"
                  onClick={() => handleSetAmount()}
                >
                  {t(`${page}.form.label.maxAmount`)}
                </button>
              </div>
              <TextInput
                className="mt-4"
                label={`${t(`${page}.form.label.fee`)} (${
                  networkData?.fixed || 0
                } ${id} + ${(networkData?.percent || 0) * 100}%)`}
                note={`≈ ${feeUsdt} USDT`}
                name="transaction"
                type="text"
                disabled
                placeholder={`0.00 ${id}`}
                value={`${fee} ${id}`}
                required
              />
              <div className={cn(hideTotal && styles.hidden)}>
                <TextInput
                  className={styles.field}
                  label={t(`${page}.form.label.total`)}
                  name="total"
                  type="number"
                  error={totalError}
                  placeholder={`0.00000000 ${id}`}
                  onChange={totalChanged}
                  numberValidated={true}
                  ref={totalInput}
                  onWrongPaste={() =>
                    toast.error(t(`${page}.errors.total.paste`))
                  }
                />
                <p className={styles.price_total}>
                  ${TraderPrepare.fixedFromInt(priceUsdt, 2)}
                </p>
              </div>

              <div className="mt-4 shadow-md rounded-md px-4 py-2">
                <h2 className="text-base font-semibold">Balances:</h2>
                <div className="flex justify-between mt-4">
                  <div>
                    <div className="font-light text-base">
                      {t(`${page}.available`)}:
                    </div>
                    <div className="text-base font-light">
                      <div className="font-semibold">
                        {balanceData?.total_available} {id}
                      </div>
                      <div className="text-sm">
                        ${balanceData?.total_available_usdt}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="font-light text-base">
                      {t(`${page}.total`)}:
                    </div>
                    <div className="text-base font-light">
                      <div className="font-semibold">
                        {balanceData?.total_balance} {id}
                      </div>
                      <div className="text-sm">
                        ${balanceData?.total_balance_usdt}
                      </div>
                    </div>
                  </div>
                </div>
                {balanceData.total_available < balanceData.total_balance && (
                  <div className={styles.balance}>
                    <div className={styles.category}>
                      {t(`${page}.locked`)}:
                    </div>
                    <div className={styles.details}>
                      <div
                        className={styles.currency}
                      >{`${TraderPrepare.formatNumCurrency(
                        balanceData.total_balance_num -
                          balanceData.total_available_num,
                        tickerDecimalRounding
                      )} ${id}`}</div>
                      <div className={styles.price}>
                        $
                        {TraderPrepare.formatNumCurrency(
                          balanceData.total_balance_usdt_num -
                            balanceData.total_available_usdt_num,
                          tickerDecimalRounding
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* {(verificationData?.verification.status !== 2) &&
                                <div className={cn(styles.wrap, styles.bgError)}>
                                    {(!verificationData?.statuses?.address?.approved) &&
                                        <div className={styles.balance}>
                                            <div className={styles.category}>Lvl.1 Kyc:</div>
                                            <div className={styles.details}>
                                                {!verificationData?.statuses?.personal?.approved && <>
                                                    Withdrawal
                                                    of {settings_withdraw.limits.personal_full_name_verified.max} {all_currency[settings_withdraw.limits.personal_address_verified.currency]} per
                                                    day <br/></>}

                                                <Link to="/verification" className={styles.linkLimit}>Verify Now</Link>
                                            </div>

                                        </div>}

                                    {(!verificationData?.statuses?.address?.approved) &&
                                        <div className={styles.balance}>
                                            <div className={styles.category}>Lvl.2 KyC:</div>
                                            <div className={styles.details}>
                                                {!verificationData?.statuses?.personal_address_verified?.approved && <>
                                                    Withdrawal
                                                    of {settings_withdraw.limits.personal_address_verified.max} {all_currency[settings_withdraw.limits.personal_address_verified.currency]} per
                                                    day <br/></>}
                                                <Link to="/verification" className={styles.linkLimit}>Verify Now</Link>
                                            </div>
                                        </div>}
                                </div>} */}

              <div className="py-2 px-4 rounded-md shadow-md mt-4">
                <div>
                  <div className="text-base font-semibold">
                    {t(`${page}.total`)}:
                  </div>
                  <div className="mt-4">
                    <div className="text-base font-semibold">
                      {inputCalculate() || 0} {id}
                    </div>
                    <div className="font-light text-sm">
                      Fee: {fee || 0} {id}
                    </div>
                  </div>
                </div>
              </div>
              <button
                className=" w-full p-5 font-bold uppercase rounded-md mt-4 bg-amber-500 hover:bg-amber-400"
                type="submit"
              >
                {t(`${page}.form.label.submit`)}
              </button>
            </form>
          </div>
        )}
      </>
    );
  };

  /**
   * Show Confirm
   *
   * @returns {Element}
   */
  const showConfirm = () => {
    return (
      <>
        {orderData.isVisible && (
          <Confirm
            orderData={orderData}
            handleClose={onCloseConfirmWindow}
            coinType={activeCurrencyData.type}
          />
        )}
      </>
    );
  };

  /**
   *
   */
  const recalculateForm = () => {
    setVisibleWithdraw(true);

    // Run check init form
    let intervalId = setInterval(() => {
      if (totalInput.current) {
        clearInterval(intervalId);

        totalInput.current.value = Number(searchParams.get("amount"));

        totalChanged();
      }
    }, 300);

    // Clear interval
    setTimeout(() => {
      clearInterval(intervalId);
    }, 10000);
  };

  /**
   *
   */
  useEffect(() => {
    if (currency_iso3 === undefined) {
      handleSetAmount(0);
    }
  }, [network, currency_iso3]);

  /**
   *
   */
  useEffect(() => {
    if (options.length === 1) {
      setNetwork(options[0].network);
    }
  }, [options]);

  /**
   *
   */
  useEffect(() => {
    if (initialRender && currency_iso3 !== undefined) {
      initialRender = false;

      // Check coin exist
      if (all_currency_flip[currency_iso3.toUpperCase()] === undefined) {
        setVisibleCoinError(true);

        return;
      }

      // Search network object exclude space
      const type = Object.values(items).find(
        (item) =>
          String(item.name.replace(/\s/g, "")).toLowerCase() ===
          String(searchParams.get("network")).toLowerCase()
      );
      const networkId = type !== undefined ? type.network : 0;

      if (coinWithdrawDataConverted[networkId] === undefined) {
        setVisibleNetworkError(true);

        return;
      }

      setVisibleWithdraw(false);

      formik.values.address = searchParams.get("destination_address");

      const destination_tag = searchParams.get("destination_tag");
      if (destination_tag) {
        formik.values.tag = destination_tag;
      }

      // Set Network
      formik.values.network = networkId;
      setNetwork(networkId);

      // Set amount
      const amount = searchParams.get("amount");
      if (amount) {
        formik.values.amount = amount;
      }

      // Check form
      //console.log('1. useEffect', currency_iso3);
      formik.validateForm().then((r) => {
        if (Object.keys(r).length === 0) {
          formik.submitForm().then((r) => {
            //console.log('submitForm');
          });
        } else {
          recalculateForm();
        }
      });
    }
  }, [currency_iso3, initialRender]);

  /**
   * Fix
   */
  useEffect(() => {
    if (tickerPair && visibleWithdraw) {
      // totalChanged();
    }
  }, [tickerPair, visibleWithdraw]);

  /**
   * Change send to direction (for fiat / crypto)
   */
  useEffect(() => {
    if (activeCurrencyData && activeCurrencyData.type === 0) {
      setSendToType(1);
    }
  }, [activeCurrencyData]);

  /**
   * Show pre-loader, while waiting current pair
   */
  if (currency_iso3 !== undefined && !visibleCoinError && !balanceData) {
    return <Loader />;
  }

  /**
   *
   */
  return (
    <>
      {visibleCoinError || visibleNetworkError ? (
        <div className={styles.page}>
          <div className={cn("container", styles.container)}>
            <div className={styles.wrapper}>
              {visibleCoinError && (
                <div className={styles["error-alert"]}>
                  Coin {currency_iso3.toUpperCase()} is not supported
                </div>
              )}

              {visibleNetworkError && (
                <div className={styles["error-alert"]}>
                  Network {String(searchParams.get("network")).toUpperCase()} is
                  not supported
                </div>
              )}

              <div className={styles["button-container"]}>
                <Link
                  className={cn("button-stroke", styles.button)}
                  to="/wallet-overview"
                >
                  View wallet
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {currency_iso3 !== undefined && balanceData && (
            <div className={styles.page}>
              <div className={cn("container", styles.container)}>
                <div className={styles.wrapper}>
                  {showForm()}
                  {showConfirm()}
                </div>
              </div>
            </div>
          )}

          {currency_iso3 === undefined && (
            <>
              {showForm()}
              {showConfirm()}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Withdraw;
