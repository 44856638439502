import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import styles from "./WalletOverview.module.sass";
import Wallet from "../../components/Wallet";
import Icon from "../../components/Icon";

import AssetBalances from "./AssetBalances";
import { fetchUserBalances } from "../../store/actions/User/FetchUserBalances";

const page = "wallet";

const WalletOverview = React.memo(() => {
  /**
   *
   * @type {Dispatch<AnyAction>}
   */
  const dispatch = useDispatch();

  /**
   *
   */
  const { t } = useTranslation();

  /**
   *
   * @type {{}}
   */
  const walletNames = {
    spot: t(`${page}.navigation.0.title`, { balance: "" }),
    // margin: t(`${page}.navigation.1.title`),
    margin: t(`${page}.navigation.7.title`, { balance: "" }),
    funding: t(`${page}.navigation.2.title`, { balance: "" }),
  };

  /**
   * Get params from URL
   */
  let { walletType } = useParams();
  if (walletType === undefined) {
    walletType = "spot";
  }

  /**
   * Get data from global store
   */
  const availableBalance = useSelector((state) => state.user.available_balance);
  const balances = useSelector(
    (state) => ({ ...state.user.user_balances }),
    shallowEqual
  );
  const isBalanceShown = useSelector((state) => state.user.isBalanceShown);

  /**
   * Local state
   */
  const [search, setSearch] = useState("");
  const [filteredWalletsList, setFilteredBalances] = useState(balances);
  const [walletName, setWalletName] = useState("");

  /**
   * @param e
   */
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // const handleShowHideBalance = () => {
  //     dispatch(switchIsBalanceShown());
  // }

  /**
   * Init on start
   */
  useEffect(() => {
    dispatch(fetchUserBalances());
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (walletType === "futures") {
      setWalletName(walletNames["margin"]);
    } else {
      setWalletName(walletNames[walletType]);
    }
  }, [walletType, t]);

  /**
   *
   */
  useEffect(() => {
    const filtered = Object.keys(balances)
      .sort((a, b) => {
        const compareResult =
          balances[b][walletType].total_available_num -
          balances[a][walletType].total_available_num;

        if (compareResult === 0) {
          return (
            balances[b][walletType].total_available_usdt_num -
            balances[a][walletType].total_available_usdt_num
          );
        }

        return compareResult;
      })
      .filter((key) => key.toLowerCase().includes(search.trim().toLowerCase()))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: balances[key][walletType],
        };
      }, {});

    setFilteredBalances(filtered);
  }, [JSON.stringify(balances), search, walletType]);

  /**
   * Check balances and display preloader
   */
  // if (Object.keys(balances).length === 0) {
  //    return <Loader />
  // }

  /**
   * Render and return HTML
   */
  return (
    <Wallet>
      <div className="flex flex-col mt-10 sm:px-10 px-5 gap-2 border-t pt-5 dark:border-t-slate-700">
        <h4 className="text-xl font-semibold text-gray-400">{walletName}</h4>
        <div className="flex flex-col ">
          <div className="flex gap-1">
            <div className="font-semibold text-xl ">
              {isBalanceShown ? availableBalance[walletType].btc : "**********"}
            </div>
            <div className="text-gray-400">BTC</div>
          </div>
          <div className="text-sm font-light text-gray-400">
            ≈ ${isBalanceShown ? availableBalance[walletType].usdt : "******"}
          </div>
        </div>
      </div>
      <div className="mt-10 sm:px-10 px-5 relative">
        <input
          className="outline outline-amber-500 rounded-md p-3 focus:outline-amber-400 hover:outline-amber-400 w-full bg-transparent"
          type="text"
          value={search}
          onChange={handleChange}
          name="searchBar"
          placeholder={t(`${page}.search`)}
          autoComplete="off"
          required
          autoFocus
        />
        <button className="absolute top-1/2 right-14 -translate-y-1/2">
          <Icon name="search" size="20" />
        </button>
      </div>
      <AssetBalances
        balanceData={filteredWalletsList}
        walletType={walletType}
        className="mt-10 px-5"
      />
    </Wallet>
  );
});

export default WalletOverview;
