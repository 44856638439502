import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import cn from "classnames";
import styles from "./Transfer.module.sass";

import Icon from "../Icon";
import TextInput from "../Forms/TextInput";
import Dropdown from "../Dropdown";
import Balance from "./Balance";
import MaxAmountButton from "./MaxAmountButton";

import axios from "../../utils/Api";
import Modal from "../Modal";
import ConfirmTwoFaCodeFields from "../ConfirmTwoFaCodeFields";
import { getUserBalances } from "../../store/utils/GetUserBalances";
import TraderPrepare from "../../utils/TraderPrepare";

const page = "wallet.transfer";

const Transfer = ({ ticker: tickerDefault = null, walletType, onClose }) => {
  /**
   * Translate
   */
  const { t } = useTranslation();

  const [ticker] = useState(tickerDefault);

  const [fromDirection, setFromDirection] = useState("");
  const [fromDirectionOptions, setFromDirectionOptions] = useState([]);

  const [toDirection, setToDirection] = useState("");
  const [toDirectionOptions, setToDirectionOptions] = useState([]);

  const [transferSendToError, setTransferSendToError] = useState(false);
  const [transferAmountError, setTransferAmountError] = useState(false);

  const [recipient, setRecipient] = useState("");
  const [sendTo, setSendTo] = useState(null);

  const [confirmCode, setConfirmCode] = useState(false);
  const [requiredFields, setRequiredFields] = useState();
  const [confirmCodeData, setConfirmCodeData] = useState(null);

  const amountInputRef = useRef(null);

  /**
   * Get data from store
   */
  const all_currency_flip = useSelector(
    (state) => state.config.all_currencies_flip
  );

  const balanceData = useSelector((state) => getUserBalances(state, ticker));

  const tickerDecimalRounding = useSelector((state) => {
    const tickerId = state.config.all_currencies_flip[ticker];

    return state.config.currency_data[tickerId].decimal_rounding;
  });

  /**
   * Enable / Disable send button
   */
  const [submitting, setSubmitting] = useState(true);

  const transferOptions = {
    1: t(`${page}.transferOptions.myWallet`),
    2: t(`${page}.transferOptions.email`),
  };

  const walletTypesRaw = {
    spot: t(`${page}.walletTypes.spot`),
    margin: t(`${page}.walletTypes.margin`),
    funding: t(`${page}.walletTypes.funding`),
  };

  const walletTypesFlipRaw = useMemo(() => {
    return Object.entries(walletTypesRaw).reduce(
      (obj, [key, value]) => ({ ...obj, [value]: key }),
      {}
    );
  });

  const walletTypes = {
    spot:
      t(`${page}.walletTypes.spot`) +
      " ($" +
      TraderPrepare.formatNumCurrency(
        balanceData.spot.total_available_usdt_num,
        tickerDecimalRounding
      ) +
      ")",
    margin:
      t(`${page}.walletTypes.margin`) +
      " ($" +
      TraderPrepare.formatNumCurrency(
        balanceData.margin.total_available_usdt_num,
        tickerDecimalRounding
      ) +
      ")",
    funding:
      t(`${page}.walletTypes.funding`) +
      " ($" +
      TraderPrepare.formatNumCurrency(
        balanceData.funding.total_available_usdt_num,
        tickerDecimalRounding
      ) +
      ")",
  };

  /**
   * Flip wallet types
   * @returns {{}}
   */
  const walletTypesFlip = useMemo(() => {
    return Object.entries(walletTypes).reduce(
      (obj, [key, value]) => ({ ...obj, [value]: key }),
      {}
    );
  });

  /**
   * Form submit handler
   */
  const handleSubmit = () => {
    const transferAmount =
      amountInputRef.current && amountInputRef.current.value;
    if (transferAmount === "" || Number(transferAmount) === 0) {
      toast.error();

      setTransferAmountError(true);

      if (amountInputRef.current) {
        amountInputRef.current.focus();
      }

      return false;
    }

    // Prepare inputs data
    const inputs = {
      deposit_type_from: walletTypesFlipRaw[fromDirection.split(" ")[0]],
      deposit_type_to: walletTypesFlipRaw[toDirection.split(" ")[0]],
      currency_id: all_currency_flip[ticker],
      amount: transferAmount,
    };

    // If exit recipient
    if (recipient !== "") {
      inputs.deposit_email_to = recipient;
      inputs.send_to_email = 1;
      inputs.send_to_phone = 1;
    } else {
      delete inputs["deposit_email_to"];
      delete inputs["send_to_email"];
      delete inputs["send_to_phone"];
    }

    //Do POST request
    axios
      .post("/api/transactions/transfer", inputs)
      .then((response) => {
        const data = response.data;

        // Disable button for sometime
        setSubmitting(false);

        // If response status is false
        if (!data.status) {
          toast.error(response.data.errors[0]);

          if (response.data.code === 34) {
            setTransferAmountError(true);
          }

          if (amountInputRef.current) {
            amountInputRef.current.focus();
          }

          // Enable button
          setSubmitting(true);
        }

        // If response status is true, then Success registered
        if (data.status) {
          if (recipient !== "") {
            setRequiredFields(data.data.required);
            setConfirmCode(true);

            setConfirmCodeData({
              order_id: data.data.order.id,
            });
          } else {
            toast.success(t(`${page}.success`));
            onClose();
          }
        }
      })
      .catch(() => {
        // Reset submitting
        setSubmitting(true);
      });
  };

  /**
   *
   */
  const handleSwitchDirection = () => {
    const walletTypesValues = Object.values(walletTypes);
    const flipValues = Object.entries(walletTypesValues).reduce(
      (obj, [key, value]) => ({ ...obj, [value]: key }),
      {}
    );

    // To direction
    const indexTo = flipValues[toDirection];
    walletTypesValues.splice(indexTo, 1);
    setToDirection(walletTypesValues[0]);
    setToDirectionOptions(walletTypesValues);
    handleSwitchWalletType(fromDirection, "to");

    // From direction
    setFromDirection(toDirection);
    handleSwitchWalletType(toDirection, "from");
  };

  /**
   * Flip wallet types
   *
   * @param value
   * @param direction
   */
  const handleSwitchWalletType = (value, direction) => {
    if (direction === "from") {
      setFromDirection(value);

      // Change Direction
      const walletTypesValues = Object.values(walletTypes);
      const flipValues = Object.entries(walletTypesValues).reduce(
        (obj, [key, value]) => ({ ...obj, [value]: key }),
        {}
      );
      const indexTo = flipValues[value];

      walletTypesValues.splice(indexTo, 1);
      setToDirection(walletTypesValues[0]);
      setToDirectionOptions(walletTypesValues);
    } else {
      setToDirection(value);
    }
  };

  /**
   * Init Form after open
   */
  const initTransfer = (recipient = "") => {
    /**
     * From direction
     */
    setFromDirectionOptions(Object.values(walletTypes));

    const typeFrom = walletTypes[walletType];
    setFromDirection(typeFrom);

    // find index selected type
    const index = Object.keys(walletTypes).findIndex(
      (item) => item === walletType
    );

    /**
     * To direction
     */
    const toOptions = Object.values(walletTypes);
    if (recipient === "") {
      toOptions.splice(index, 1);
    }
    setToDirectionOptions(toOptions);

    let typeTo = toOptions[0];
    if (recipient !== "") {
      typeTo = toOptions[index];
    }

    setToDirection(typeTo);
  };

  /**
   *
   * @param value
   */
  const handleSetAmount = (value = null) => {
    amountInputRef.current.value = value;
    setTransferAmountError(false);
  };

  const handleChangeRecipient = (e) => {
    setRecipient(e.target.value);
    initTransfer(e.target.value);
  };

  const handleChangeSendToType = (v) => {
    setSendTo(v);
    setTransferSendToError(false);
  };

  /**
   *
   */
  useEffect(() => {
    initTransfer();
    setTransferAmountError(true);
    setTransferSendToError(true);
  }, []);

  /**
   * Render HTML
   */
  return (
    <>
      <div>
        <div className="text-2xl font-semibold">
          {sendTo === transferOptions[2]
            ? t(`${page}.titleEmail`)
            : t(`${page}.title`)}
        </div>
        <Balance className="mt-2" ticker={ticker} walletTypesFlip={walletTypesFlipRaw} />
        <div className="mt-5">
          <Dropdown
            className={cn(
              styles.dropdown,
              transferSendToError ? styles.error : ""
            )}
            label={t(`${page}.form.label.send`)}
            value={sendTo}
            setValue={(value) => handleChangeSendToType(value)}
            options={Object.values(transferOptions)}
          />
        </div>

        {sendTo && (
          <>
            {sendTo === transferOptions[2] && (
              <div className="mt-5">
                <TextInput
                  label={t(`${page}.form.label.recipient`)}
                  name="recipient"
                  type="text"
                  value={recipient}
                  onChange={handleChangeRecipient}
                  className={styles.field}
                  autoComplete="off"
                />
              </div>
            )}

            <div className="mt-5">
              <Dropdown
                className={styles.dropdown}
                label={t(`${page}.form.label.from`)}
                value={fromDirection}
                setValue={(value) => handleSwitchWalletType(value, "from")}
                options={fromDirectionOptions}
              />{" "}
              {/* <div className={styles.note}>{balancesFrom + ' ' + balanceData.currency.iso3 + ' available'}</div> */}
              <Balance
                ticker={ticker}
                format="note"
                walletType={walletTypesFlipRaw[fromDirection.split(" ")[0]]}
              />
              {/* {sendTo === transferOptions[1] && (
                <div
                  className={styles.sign}
                  onClick={() => handleSwitchDirection()}
                >
                  <Icon name="arrows" size="16" />
                </div>
              )} */}
            </div>
            {sendTo === transferOptions[1] && (
              <div className="mt-5">
                <Dropdown
                  className={styles.dropdown}
                  label={t(`${page}.form.label.to`)}
                  value={toDirection}
                  setValue={(value) => handleSwitchWalletType(value, "to")}
                  options={toDirectionOptions}
                />
                <Balance
                  ticker={ticker}
                  format="note"
                  walletTypesFlip={walletTypesFlipRaw}
                  walletType={walletTypesFlipRaw[toDirection.split(" ")[0]]}
                />
              </div>
            )}

            <div className="mt-5">
              <TextInput
                type="number"
                label={t(`${page}.form.label.amount`)}
                ref={amountInputRef}
                numberValidated={true}
                error={transferAmountError}
                autoComplete="off" // note={balancesFrom + ' ' + balanceData.currency.iso3 + ' available'}
              />
              <div className="flex justify-between">
                <Balance
                  ticker={ticker}
                  format="note"
                  walletTypesFlip={walletTypesFlipRaw}
                  walletType={walletTypesFlipRaw[fromDirection.split(" ")[0]]}
                />
                <MaxAmountButton
                  className="text-amber-500 font-semibold"
                  handleSetAmount={handleSetAmount}
                  ticker={ticker}
                  walletType={walletTypesFlipRaw[fromDirection.split(" ")[0]]}
                />
              </div>
            </div>
          </>
        )}

        <button
          type="button"
          className="rounded-md p-3 bg-amber-500 hover:bg-amber-400 font-semibold w-full cursor-pointer text-base mt-5"
          disabled={!submitting || !sendTo}
          onClick={handleSubmit}
        >
          {t(`${page}.form.submit`)}
        </button>
      </div>
      <Modal
        disableGoBackButton
        disableOutsideClose={true}
        visible={confirmCode}
        title={t(`twoFaInputs.title`)}
        onClose={() => {
          setConfirmCode(false);
        }}
      >
        <ConfirmTwoFaCodeFields
          requestUrl="/api/transactions/transfer"
          requiredFields={requiredFields}
          requestData={confirmCodeData}
          onSuccess={() => {
            setConfirmCode(false);

            toast.success(t(`${page}.success`));

            // Close transfer window
            onClose();
          }}
        />
      </Modal>
    </>
  );
};

export default Transfer;
