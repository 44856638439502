import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./Balance.module.sass";
import { getUserBalances } from "../../../store/utils/GetUserBalances";
import TraderPrepare from "../../../utils/TraderPrepare";
import cn from "classnames";

const page = "wallet.transfer";

const Balance = ({
  ticker,
  format = "default",
  walletType = null,
  className,
}) => {
  const { t } = useTranslation();

  /**
   * Get data from store
   */
  const balanceData = useSelector((state) => getUserBalances(state, ticker));
  const tickerDecimalRounding = useSelector((state) => {
    const tickerId = state.config.all_currencies_flip[ticker];

    return state.config.currency_data[tickerId].decimal_rounding;
  });

  const balance = balanceData[walletType];

  const [availableBalance, setAvailableBalance] = useState({
    ticker: 0,
    usdt: 0,
  });

  /**
   * Update total balance
   */
  useEffect(() => {
    if (format === "note") {
      return;
    }

    const amount =
      balanceData.spot.total_available_num +
      balanceData.margin.total_available_num +
      balanceData.funding.total_available_num;

    const amount_usdt =
      balanceData.spot.total_available_usdt_num +
      balanceData.margin.total_available_usdt_num +
      balanceData.funding.total_available_usdt_num;

    setAvailableBalance({
      ticker: TraderPrepare.formatNumCurrency(amount, tickerDecimalRounding),
      usdt: TraderPrepare.formatNumCurrency(amount_usdt, 2),
    });
  }, []);

  /**
   * Render HTML and return wallet type balance
   */
  if (format === "note") {
    return (
      <div className="text-sm font-light">
        {balance
          ? `${balance.total_available} ${balanceData.currency.iso3} ${t(
              `${page}.form.note.available`
            )}`
          : ""}
      </div>
    );
  }

  /**
   * Render HTML and return total balance
   */
  return (
    <div className={cn("shadow-md p-5 rounded-md", className)}>
      <div className="text-base font-light">
        {t(`${page}.availableBalance`)}
      </div>
      <div className="mt-2">
        <div className="text-base font-semibold">
          {availableBalance.ticker} {balanceData.currency.iso3}
        </div>
        <div className="text-sm font-light">${availableBalance.usdt}</div>
      </div>
    </div>
  );
};

export default Balance;
