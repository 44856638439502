import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {toast} from "react-hot-toast";

import cn from "classnames";
import styles from './ConfirmTwoFaCodeFields.module.sass';

import TextInput from "../Forms/TextInput";

import fetchUserAccountInfo from "../../store/actions/User/FetchUserAccountInfo";
import axios from "../../utils/Api"

import {TYPE_LOGIN} from "../../utils/LoginTypes";
import clevertap from "clevertap-web-sdk";

const page = "twoFaInputs";

const ConfirmTwoFaCodeFields = (props) => {
    /**
     * Extract all props
     */
    const {
        onSuccess,
        requestUrl,
        requiredFields,
        requestParams = {},
        requestData = {},
        className,
        successMessage = null,
        cancelBtn,
        onCancel
    } = props;

    /**
     *
     * @type {Dispatch<AnyAction>}
     */
    const dispatch = useDispatch();

    const accountInfo = useSelector(state => state.user.account_info);

    /**
     *
     */
    const {t} = useTranslation();

    /**
     *
     */
    const [googleCode, setGoogleCode] = useState('');
    const [googleCodeError, setGoogleCodeError] = useState('');
    const [phoneCode, setPhoneCode] = useState('');
    const [phoneCodeError, setPhoneCodeError] = useState('');
    const [emailCode, setEmailCode] = useState('');
    const [emailCodeError, setEmailCodeError] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [pinCodeError, setPinCodeError] = useState('');
    const [countReset, setCountReset] = useState(null);

    /**
     *
     */
    const clearFields = () => {
        setEmailCode('');
        setPhoneCode('');
        setGoogleCode('');
        setPinCode('');
    }

    /**
     *
     * @returns {Promise<void>}
     */
    const handleConfirm = async () => {
        let isValid = true;

        if (requiredFields?.includes(TYPE_LOGIN.ON_MAIL)) {
            if (emailCode === '') {
                setEmailCodeError(t(`${page}.errors.required`));
                isValid = false;
            }

            if (emailCode.length !== 6) {
                setEmailCodeError(t(`${page}.errors.length`));
                isValid = false;
            }
        }

        if (requiredFields?.includes(TYPE_LOGIN.ON_GOOGLE)) {
            if (googleCode === '') {
                setGoogleCodeError(t(`${page}.errors.required`));
                isValid = false;
            }

            if (googleCode.length !== 6) {
                setGoogleCodeError(t(`${page}.errors.length`));
                isValid = false;
            }

            if (googleCodeError) {
                isValid = false;
            }
        }

        if (requiredFields?.includes(TYPE_LOGIN.ON_PHONE)) {
            if (phoneCode === '') {
                setPhoneCodeError(t(`${page}.errors.required`));
                isValid = false;
            }

            if (phoneCode.length !== 6) {
                setPhoneCodeError(t(`${page}.errors.length`));
                isValid = false;
            }
        }

        if (requiredFields?.includes(TYPE_LOGIN.ON_PERSONAL_PIN)) {
            if (pinCode === '') {
                setPinCodeError(t(`${page}.errors.required`));
                isValid = false;
            }

            if (pinCode.length !== 6) {
                setPinCodeError(t(`${page}.errors.length`));
                isValid = false;
            }
        }

        // Stop send confirm if it has error
        if (!isValid) {
            return;
        }

        const toastConfirm = toast.loading(t(`toast.loading`));

        const response = await axios({
            url: requestUrl,
            method: 'POST',
            data: {
                ...requestData,
                email_code: requiredFields.includes(TYPE_LOGIN.ON_MAIL) ? emailCode : undefined,
                google_code: requiredFields.includes(TYPE_LOGIN.ON_GOOGLE) ? googleCode : undefined,
                phone_code: requiredFields.includes(TYPE_LOGIN.ON_PHONE) ? phoneCode : undefined,
                personal_pin: requiredFields.includes(TYPE_LOGIN.ON_PERSONAL_PIN) ? pinCode : undefined,
                send_to_email: 0,
                send_to_phone: 0,
            },
            params: {
                ...requestParams,
            }
        });

        const data = response.data;

        if (!data.status) {
            if('errors' in data && !('data' in data)){
                const key = Object.keys(data.errors);

                toast.error(`${t('toast.error')}: ${data.errors[key]}`, {
                    id: toastConfirm,
                });
            } else {
                if (data.code === 33) {
                    setPinCodeError(true);

                    toast.error(`${t('toast.error')}: ${data.message}`, {
                        id: toastConfirm,
                    });
                } else {
                    if (Object.values(data.data.errors)[0]) {
                        setEmailCodeError(`${data.data.errors?.email_code?.[0]}. Attempts Left: ${data.data.attempts_left.email_2fa}`);
                        setGoogleCodeError(`${data.data.errors?.google_code?.[0]}. Attempts Left: ${data.data.attempts_left.google_2fa}`);
                        setPhoneCodeError(`${data.data.errors?.phone_code?.[0]}. Attempts Left: ${data.data.attempts_left.phone_2fa}`);

                        toast.error(`${t('toast.error')}: ${Object.values(data.data.errors)[0][0]?.code ?? ['Incorrect pin code', 'Code is not valid'].includes(Object.values(data.data.errors)[0][0]) ? t('toast.errorCode.incorrectCode') : Object.values(data.data.errors)[0][0]}`, {
                            id: toastConfirm,
                        });
                    }
                }
            }
        } else {
            clearFields();

            toast.success(successMessage ?? t(`${page}.success`), {
                id: toastConfirm
            })

            dispatch(fetchUserAccountInfo());

            if (onSuccess) {
                onSuccess(data);
            }
        }
    }

    /**
     * @param v
     * @param type
     */
    const handleChange = (v, type) => {
        switch (type) {
            case TYPE_LOGIN.ON_MAIL:
                setEmailCodeError('');
                setEmailCode(v);
                break;
            case TYPE_LOGIN.ON_GOOGLE:
                setGoogleCodeError('');
                setGoogleCode(v);
                break;
            case TYPE_LOGIN.ON_PHONE:
                setPhoneCodeError('');
                setPhoneCode(v);
                break;
            case TYPE_LOGIN.ON_PERSONAL_PIN:
                setPinCodeError('');
                setPinCode(v);
                break;
        }
    }

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }

        clevertap.event.push("user_dismiss_2fa", {
            userId: accountInfo?.profile?.id
        })
    }

    /**
     *
     */
    useEffect(() => {
        const interval = setInterval(() => {
            const cr = Math.floor(new Date().getSeconds() / 30);
            setCountReset(cr);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    /**
     *
     */
    useEffect(() => {
        if (googleCode.length) {
            setGoogleCode('');
            setGoogleCodeError(t(`${page}.errors.expired`));
        }
    }, [countReset])

    /**
     * Render HTML
     */
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                handleConfirm();
            }}
        >
            <div className={cn(styles.form_body, className)}>
                {requiredFields?.includes(TYPE_LOGIN.ON_MAIL) &&
                    <TextInput
                        label={t(`${page}.${TYPE_LOGIN.ON_MAIL}.label`)}
                        note={t(`${page}.${TYPE_LOGIN.ON_MAIL}.note`)}
                        name="code"
                        type="code"
                        maxLength={6}
                        autoComplete="off"
                        value={emailCode}
                        onChange={(e) => handleChange(e.target.value, TYPE_LOGIN.ON_MAIL)}
                        error={emailCodeError ?? ''}
                        required
                    />
                }
                {requiredFields?.includes(TYPE_LOGIN.ON_PHONE) &&
                    <TextInput
                        label={t(`${page}.${TYPE_LOGIN.ON_PHONE}.label`)}
                        note={t(`${page}.${TYPE_LOGIN.ON_PHONE}.note`)}
                        name="phone_code"
                        type="text"
                        maxLength={6}
                        autoComplete="off"
                        value={phoneCode}
                        onChange={(e) => handleChange(e.target.value, TYPE_LOGIN.ON_PHONE)}
                        error={phoneCodeError ?? ''}
                        required
                    />
                }
                {requiredFields?.includes(TYPE_LOGIN.ON_GOOGLE) &&
                    <TextInput
                        label={t(`${page}.${TYPE_LOGIN.ON_GOOGLE}.label`)}
                        note={t(`${page}.${TYPE_LOGIN.ON_GOOGLE}.note`)}
                        name="code"
                        type="code"
                        maxLength={6}
                        autoComplete="off"
                        value={googleCode}
                        onChange={(e) => handleChange(e.target.value, TYPE_LOGIN.ON_GOOGLE)}
                        error={googleCodeError ?? ''}
                        required
                    />
                }
                {requiredFields?.includes(TYPE_LOGIN.ON_PERSONAL_PIN) &&
                    <TextInput
                        label={t(`${page}.${TYPE_LOGIN.ON_PERSONAL_PIN}.label`)}
                        note={t(`${page}.${TYPE_LOGIN.ON_PERSONAL_PIN}.note`)}
                        name="code"
                        type="code"
                        maxLength={6}
                        autoComplete="off"
                        value={pinCode}
                        onChange={(e) => handleChange(e.target.value, TYPE_LOGIN.ON_PERSONAL_PIN)}
                        error={pinCodeError ?? ''}
                        required
                    />
                }
            </div>
            <div className={`flex ${cancelBtn ? 'justify-between' : 'justify-center'}`}>
                {cancelBtn && <button
                    className="bg-red-500 hover:bg-red-400 rounded-md p-3 font-bold"
                    type="button"
                    onClick={handleCancel}
                >
                    {t(`${page}.cancel`)}
                </button>}
                <button
                    className="bg-amber-500 hover:bg-amber-400 rounded-md p-3 font-bold"
                    type="submit"
                >
                    {t(`${page}.confirm`)}
                </button>
            </div>
        </form>
    )
}

export default ConfirmTwoFaCodeFields;
