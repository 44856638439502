import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BaseButton } from "@/components/Ui";

const List = ({
  id,
  icon,
  balanceData,
  walletType,
  disabledButtons,
  openHistoryModal,
  openDepositModal,
  openWithdrawModal,
  openTransferModal,
}) => {
  const { t } = useTranslation();
  const isBalanceShown = useSelector((state) => state.user.isBalanceShown);

  return (
    <tr className="relative font-semibold cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800 after:absolute after:content-[''] after:left-0 after:bottom-0 after:h-1 after:w-full after:border-b dark:after:border-b-gray-600">
      <td>
        <div
          className="flex items-center gap-3"
          onClick={() => {
            openHistoryModal(id);
          }}
        >
          <div className="h-6 w-6">
            <img loading="lazy" src={icon} alt={id} />
          </div>
          <div className="text-base">{id}</div>
        </div>
      </td>
      <td>
        <div className="text-base">
          {isBalanceShown ? balanceData.total_balance : "********"}
          <p className="font-light text-sm">
            ${isBalanceShown ? balanceData.total_balance_usdt : "****"}
          </p>
        </div>
      </td>
      <td>
        <div className="text-base">
          {isBalanceShown ? balanceData.total_available : "********"}
          <p className="font-light text-sm">
            ${isBalanceShown ? balanceData.total_available_usdt : "****"}
          </p>
        </div>
      </td>
      <td>
        <div className="text-base">
          {isBalanceShown ? balanceData.frozen : "********"}
          <p className="font-light text-sm">
            ${isBalanceShown ? balanceData.frozen_usdt : "****"}
          </p>
        </div>
      </td>
      <td>
        <div className="flex justify-end px-2 py-4 gap-2 text-base">
          {id !== "USDT" && (
            <Link
              to={`/trading/${id}USDT${
                walletType === "margin" ? "?from=futures" : ""
              }`}
              className="flex bg-amber-500 hover:bg-amber-400 cursor-pointer p-2 rounded-md font-semibold dark:text-white items-center text-black disabled:bg-amber-500/25"
            >
              <p className="px-3">{t(`wallet.btns.trade`)}</p>
              <i className="mdi mdi-trending-up text-xl"></i>
            </Link>
          )}
          {walletType !== "margin" ? (
            <BaseButton
              type="button"
              disabled={disabledButtons.deposit}
              className="flex items-center"
              onClick={() => {
                openDepositModal(id);
              }}
            >
              <p className="px-3">{t(`wallet.btns.deposit`)}</p>
              <i className="mdi mdi-menu-down-outline text-xl"></i>
            </BaseButton>
          ) : null}
          {walletType !== "margin" ? (
            <BaseButton
              className="flex items-center"
              type="button"
              disabled={disabledButtons.withdraw}
              onClick={() => openWithdrawModal(id)}
            >
              <p className="px-3">{t(`wallet.btns.withdraw`)} </p>
              <i className="mdi mdi-menu-up-outline text-xl"></i>
            </BaseButton>
          ) : null}
          <span
            className="flex bg-amber-500 hover:bg-amber-400 cursor-pointer p-2 rounded-md font-semibold items-center text-black disabled:bg-amber-500/25"
            onClick={() => {
              openTransferModal(id);
            }}
          >
            <p className="px-3">{t(`wallet.btns.transfer`)} </p>
            <i className="mdi mdi-bank-transfer text-xl"></i>
          </span>
        </div>
      </td>
    </tr>
  );
};

export default List;
