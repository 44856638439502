import React, { useState } from "react";
import cn from "classnames";
import styles from "./Dropdown.module.sass";
import OutsideClickHandler from "react-outside-click-handler";
import { NavLink } from "react-router-dom";

const Dropdown = ({ className, item, setValue }) => {
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(false);
    setValue(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(className, styles.dropdown, {
          [styles.active]: visible,
        })}
      >
        <button
          className="flex gap-1 hover:text-amber-500"
          onClick={() => setVisible(!visible)}
        >
          {item.title}
          {visible ? (
            <i className="mdi mdi-chevron-up"></i>
          ) : (
            <i className="mdi mdi-chevron-down"></i>
          )}
        </button>
        <div className={cn(styles.body, 'text-base')}>
          {item.dropdown.map((x, index) => (
            <NavLink
              className="flex items-center gap-4 px-1 mt-2 hover:text-amber-500 !fill-white hover:!fill-amber-500 font-semibold"
              activeClassName={styles.active}
              to={x.url}
              key={index}
              onClick={() => handleClick()}
            >
              <i className={cn(x.icon, "text-xl")}></i>
              {x.title}
            </NavLink>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
