import cn from "classnames";
import styles from "./Theme.module.sass";
import useDarkMode from "use-dark-mode";
import Icon from "../Icon";

const Theme = ({ className, icon, small }) => {
  const darkMode = useDarkMode(true);

  /**
   * Render HTML
   */
  return (
    <label className={cn(className, styles.theme, { [styles.small]: small })}>
      <input
        className={styles.input}
        checked={darkMode.value}
        onChange={darkMode.toggle}
        type="checkbox"
      />
      {icon ? (
        <div className="text-2xl hover:text-yellow-400">
          {darkMode.value ? (
            <i className="mdi mdi-moon-waxing-crescent"></i>
          ) : (
            <i className="mdi mdi-white-balance-sunny"></i>
          )}
        </div>
      ) : (
        <span className={styles.inner}>
          <span className={styles.box}></span>
        </span>
      )}
    </label>
  );
};

export default Theme;
