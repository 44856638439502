import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Icon from "../Icon";
import Modal from "../Modal";

const Dropdown = ({
  className,
  classLabel,
  value,
  setValue,
  options,
  label,
  error,
  iconDropdown,
  iconSize,
  classDropdownHead,
  classDropdownArrow,
  classDropdownBody,
  classDropdownOption,
  classDropdownOptionsContainer,
  classSearchBar,
  addSearchBar,
  contentInTop,
  labelHint,
  disabled,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (value) => {
    setValue(value);
    setVisible(false);
  };

  const [search, setSearch] = useState("");

  // const [labelHintModal, setLabelHintModal] = useState(false);
  const [labelHintVisible, setLabelHintVisible] = useState(false);

  /**
   *
   * @returns {JSX.Element}
   */
  const SearchBar = () => {
    const searchInput = useRef(null);

    useEffect(() => {
      setTimeout(() => {
        if (searchInput.current) {
          searchInput.current.focus();
        }
      }, 50);
    }, []);

    return (
      <div className={cn(styles.searchBar, classSearchBar)}>
        <input
          className={styles.input}
          type="text"
          value={search}
          autoFocus
          ref={searchInput}
          autoComplete="off"
          onChange={(e) => setSearch(e.target.value)}
          name="search"
        />
        <button className={styles.result}>
          <Icon name="search" size="16" />
        </button>
      </div>
    );
  };

  /**
   *
   * @returns {[]}
   */
  const getSelectOptions = () => {
    let convertedOptions = [];

    if (Array.isArray(options)) {
      for (let key in options) {
        if (typeof options[key] === "object") {
          convertedOptions.push(options[key]);
        } else {
          convertedOptions.push({
            key: options[key],
            label: options[key],
          });
        }
      }
    } else {
      for (let key in options) {
        convertedOptions.push({
          key: key,
          label: options[key],
        });
      }
    }

    // Return ready values
    return convertedOptions;
  };

  /**
   *
   * @returns {*}
   */
  const getSelectedValue = () => {
    if (Array.isArray(options)) {
      return value;
    } else {
      return options[value];
    }
  };

  /**
   * Render HTML
   */
  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div
          className={cn(
            classLabel,
            "text-base font-light pb-1",
            labelHint && styles.labelHinted
          )}
        >
          <span
            // onClick={() => labelHint && setLabelHintModal(true)}
            onMouseEnter={() => labelHint && setLabelHintVisible(true)}
            onMouseLeave={() => labelHint && setLabelHintVisible(false)}
            className={styles.labelHintedCursor}
          >
            {label}
            {labelHint && <span className={styles.labelHint}>?</span>}
            {labelHint && (
              <div
                className={cn(
                  styles.labelHintPopper,
                  labelHintVisible && styles.hintVisible
                )}
              >
                {labelHint}
              </div>
            )}
          </span>
        </div>
      )}
      <div
        className={cn(styles.dropdown, className, {
          [styles.active]: visible,
        })}
      >
        {!iconDropdown ? (
          <div
            className={cn(
              styles.head,
              error && styles.error,
              disabled && styles.disabled,
              classDropdownHead
            )}
            onClick={() => {
              if (!disabled) {
                setVisible(!visible);
              }
            }}
          >
            <div className={styles.selection}>{getSelectedValue()}</div>
            <div className={cn(styles.arrow, classDropdownArrow)}>
              <Icon name="arrow-down" size="24" />
            </div>
          </div>
        ) : (
          <button
            className="hover:text-amber-500"
            onClick={() => {
              if (!disabled) {
                setVisible(!visible);
              }
            }}
          >
            <i className={cn("text-2xl mdi", iconDropdown)}></i>
            {/* <Icon
              className={styles.classDropdownIcon}
              name={iconDropdown}
              size={iconSize}
            /> */}
          </button>
        )}
        <div
          className={cn(
            classDropdownBody,
            styles.body,
            iconDropdown && styles.classDropdownIconBody
          )}
        >
          {contentInTop}
          {addSearchBar && <SearchBar />}
          <div
            className={cn(
              classDropdownOptionsContainer,
              styles.optionsContainer
            )}
          >
            {getSelectOptions()
              .filter((v) =>
                addSearchBar
                  ? v.label.toLowerCase().includes(search.toLowerCase())
                  : true
              )
              .map((o, i) => (
                <div
                  className={cn(
                    classDropdownOption,
                    "hover:text-amber-400 font-bold hover:cursor-pointer p-2 hover:bg-gray-100 dark:hover:bg-gray-800",
                    {
                      "text-amber-600": o.key === value,
                    }
                  )}
                  onClick={() => handleClick(o.key, i)}
                  key={i}
                >
                  {o.label}
                </div>
              ))}
          </div>
        </div>
      </div>
      {error && <div className={styles.red}>{error}</div>}
      {/* {labelHint && <Modal visible={labelHint && labelHintModal} onClose={() => setLabelHintModal(false)} disableGoBackButton>
                <div style={{marginTop: '36px'}}>
                    {labelHint}
                </div>
            </Modal>} */}
    </OutsideClickHandler>
  );
};

export default Dropdown;
